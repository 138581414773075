.scrolling-area {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: calc(4rem / 16);
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
}

@media screen and (min-width: 768px) {
    .scrolling-area--xs {
        overflow-y: unset;
        overflow-x: unset;
    }
}

.scrolling-area::-webkit-scrollbar-track {
    background-color: transparent;
    transition: all 120ms ease;
    will-change: scroll-position;
}

.scrolling-area--xs::-webkit-scrollbar,
.scrolling-area::-webkit-scrollbar {
    width: calc(4rem / 16);
    height: calc(4rem / 16);
    background: var(--color-primary-lightest);
    transition: all 120ms ease;
    will-change: scroll-position;
}

.scrolling-area--xs::-webkit-scrollbar-thumb,
.scrolling-area::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    transition: all 120ms ease;
    will-change: scroll-position;
}

.scrolling-area__nav {
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
        flex-wrap: nowrap;
    }
}

@media screen and (max-width: 767px) {
    .scrolling-area--xs {
        overflow-y: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
    }
}

.scrolling-area__item {
    flex-shrink: 0;
    scroll-snap-align: start;
}