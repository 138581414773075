.main-navbar__toggle {
    border: none;
    box-shadow: none;
    width: auto;
    height: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    outline:none;
    padding-right: calc(20rem/16);
    padding-left: calc(15rem/16);

    @media screen and (min-width: 768px) {
        display: none;
    }
}
.main-navbar__toggle:hover, .main-navbar__toggle:active, .main-navbar__toggle:focus {
    outline:none;
}
.main-navbar__toggle__line {
    background-color: var(--color-primary);
    width: calc(21rem/16);
    height: calc(2rem/16);
    margin-bottom: calc(6rem/16);
    border-radius: calc(4rem/16);
    transition: transform 0.2s ease;
}
.main-navbar__toggle:hover .main-navbar__toggle__line:nth-child(1), .main-navbar__toggle:hover .main-navbar__toggle__line:nth-child(3) {
    transform: translateX(-2px);
}
.main-navbar__toggle:hover .main-navbar__toggle__line:nth-child(2) {
    transform: translateX(2px);
}
.main-navbar__toggle__line:nth-child(1) {
    margin-left: 0;
    transition: transform 0.2s ease;
}
.main-navbar__toggle__line:nth-child(2) {
    margin-left: 0;
    width: calc(13rem/16);
    opacity:1;
    transition: opacity 0.1s ease, transform 0.2s ease;
}
.main-navbar__toggle__line:nth-child(3) {
    transition: transform 0.2s ease;
    @media screen and (max-width: 767px) {
        margin-bottom:0;
    }
}
.main-navbar__toggle {
    z-index: 999;
}
.main-navbar__toggle.is-open .main-navbar__toggle__line:nth-child(1) {
    animation-timing-function: cubic-bezier(1,0,.26,.64);
    animation: 0.3s forwards firstLine;
    transform-origin: center;
}

.main-navbar__toggle.is-not-open .main-navbar__toggle__line:nth-child(1) {
    animation-timing-function: cubic-bezier(1,0,.26,.64);
    animation: 0.3s forwards firstLine-reverse;
    transform-origin: center;
}
.main-navbar__toggle.is-open .main-navbar__toggle__line:nth-child(2) {
    transition: opacity 0.2s ease-in-out;
    opacity:0;
}
.main-navbar__toggle.is-open .main-navbar__toggle__line:nth-child(3) {
    animation-timing-function: cubic-bezier(1,0,.26,.64);
    animation: 0.3s forwards lastLine;
}
.main-navbar__toggle.is-not-open .main-navbar__toggle__line:nth-child(3) {
    animation-timing-function: cubic-bezier(1,0,.26,.64);
    animation: 0.3s forwards lastLine-reverse;
    transform-origin: center;
}
@keyframes firstLine {
    0% {
        transform: translateY(0) rotate(0deg);
    }
    70% {
        transform: translateY(10px) rotate(45deg) scaleX(1.1);
    }
    100% {
        transform: translateY(10px) rotate(45deg);
    }
}

@keyframes firstLine-reverse {
    0% {
        transform: translateY(10px) rotate(45deg);
    }
    70% {
        transform: translateY(0) rotate(0deg) scaleX(1.1);
    }
    100% {
        transform: translateY(0) rotate(0deg);
    }
}


@keyframes lastLine {
    0% {
        transform: translateY(0) rotate(0deg);
    }
    70% {
        transform: translateY(-6px) rotate(-45deg) scale(1.1)
    }
    100% {
        transform: translateY(-6px) rotate(-45deg);
    }
}

@keyframes lastLine-reverse {
    0% {
        transform: translateY(-10px) rotate(-45deg);
    }
    70% {
        transform: translateY(0) rotate(0deg) scale(1.1);
    }
    100% {
        transform: translateY(0) rotate(0deg);
    }
}
