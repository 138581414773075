/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.font-bold {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
}

/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-grey { color: var(--color-mid-grey); }
.text-mid-grey {color: var(--color-mid-grey); }

/* Background Color Helper */

.bg-light-grey {
    background-color: var(--color-light-grey);
}
.bg-mid-grey {
    background-color: var(--color-mid-grey);
}

/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.d-hover-none {
    display: none;

    @media (pointer: coarse) {
        display: block;
    }
}

.no-box-shadow {
    box-shadow: none;
}

.collapse--only-mobile.collapse:not(.show) {
    @media screen and (min-width: 768px) {
        display: block;
    }
}
.box-shadow {
    box-shadow: 0 calc(10rem/16) calc(20rem/16) 0 rgba(29,29,27,0.1);
}
.fz16 {
    font-size: calc(16rem/16);
}
.fz24 {
    font-size: calc(24rem/16);
}
.fz12--mobile {
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
.fz14 {
    font-size: calc(14rem/16);
}

.z-index-30{
    z-index: 30;
}

.frc-captcha .frc-content {
    min-height: calc(18rem/16);
}
