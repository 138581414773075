.scroll-slider__area {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 0;
    position: relative;
}

.scroll-slider__item {
    scroll-snap-align: start;
    flex-shrink: 0;
}

.scroll-slider__area::-webkit-scrollbar-track {
    background-color: transparent;
}

.scroll-slider__area::-webkit-scrollbar {
    display: none;
}

.scroll-slider__area::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.scroll-slider__arrow {
    border: none;
    box-shadow: none;
    background-color: transparent;
    filter: drop-shadow(0 10px 20px rgba(29, 29, 27, 0.1));

    &:disabled {
        opacity: 0.2;
    }
}

.scroll-slider__arrow .scroll-slider__arrow-icon {
    height: calc(56rem / 16);
    width: calc(66rem / 16);
    background-color: #fff;
    color: var(--color-primary);
    font-size: calc(25rem / 16);
    display: inline-flex;
    justify-content: center;
    border: none;
    align-items: center;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.scroll-slider__arrow:not(:disabled):hover .scroll-slider__arrow-icon {
    background-color: var(--color-primary);
    color: #fff;
}

.scroll-slider__arrow--next .scroll-slider__arrow-icon {
    padding-left: calc(10rem / 16);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 14% 100%);
}

.scroll-slider__arrow--prev .scroll-slider__arrow-icon {
    padding-right: calc(10rem / 16);
    clip-path: polygon(0 0, 86% 0, 100% 100%, 0 100%);

}

.scroll-slider--arrows-grey .scroll-slider__arrow {
    filter: none;

    &:disabled {
        opacity: 0.3;
    }
}

.scroll-slider--arrows-grey .scroll-slider__arrow-icon {
    background-color: var(--color-light-grey);
}

.scroll-slider--hide-dots .scroll-slider__dots-container {
    display: none;
}

.scroll-slider__dot {
    border: none;
    box-shadow: none;
    background-color: var(--color-mid-grey);
    opacity: 0.3;
    height: calc(1rem / 16);
    width: calc(17rem / 16);
    margin-left: calc(7rem / 16);
    margin-right: calc(7rem / 16);
    border-radius: calc(30rem / 16);
}

.scroll-slider__dot.is-active {
    opacity: 1;
    padding: calc(2rem / 16);
}

.scroll-slider__dot {
    border: none;
    box-shadow: none;
    background-color: var(--color-mid-grey);
    opacity: 0.3;
    height: calc(1rem / 16);
    width: calc(17rem / 16);
    margin-left: calc(7rem / 16);
    margin-right: calc(7rem / 16);
    border-radius: calc(30rem / 16);
}

.scroll-slider__dot.is-active {
    opacity: 1;
    padding: calc(2rem / 16);
}

.scroll-slider__arrow-wrapper--xxl-d-none {
    @media screen and (min-width: 1540px){
        display: none!important;
    }
}