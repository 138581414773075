html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
figure {
    margin-bottom:0;
}

/* needed because default gutter is too big for smaller screens */
@media screen and (max-width: 767px) {
    .row:not([class*=" gx-"]):not([class*=" row-gutter--"]) {
        --bs-gutter-x: calc(16rem/16);
    }
}

.container.container {
    max-width: calc(1680rem/16);
    width: 100%;
}

.overlay-open {
    &:before {
        content:'';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.4);
        display: block;
        left:0;
        top:0;
        z-index: 9;
    }
}
body.nav-is-open{
    overflow: hidden;
}
.main-content--no-hero {
    margin-top: calc(60rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(120rem/16);
    }
}
.main-content--spacing-bottom {
    margin-bottom: calc(120rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(200rem/16);
    }
}