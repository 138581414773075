.food-table {
    position: relative;
}
.food-table__header {
    display: grid;
    padding-bottom: calc(15rem/16);
    border-bottom: 1px solid var(--color-dark);
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto;
    gap: calc(10rem/16) calc(0rem/16);
    z-index: 2;
    background-color: #fff;
    grid-template-areas:
    "alkaline . acidic"
    "pral-left pral-text pral-right";

    @media screen and (min-width: 768px) {
        padding-bottom: calc(20rem/16);
        padding-right: calc(140rem/16);
        gap: calc(10rem/16) calc(20rem/16);
        grid-template-columns: 1fr auto 1fr auto 1fr auto;
        grid-template-rows: auto;
        grid-template-areas:
        ". . . . . .";
    }
}
@media screen and (max-width: 767px) {
    .food-table__header-text {
        grid-area: pral-text;
        text-align: center;
    }
    .food-table__header-left-pral {grid-area: pral-left;}
    .food-table__header-right-pral {
        grid-area: pral-right;

    }
    .food-table__header-text-alkaline {grid-area: alkaline;}
    .food-table__header-text-acidic {grid-area: acidic;}
}
.food-table__body {
    display: flex;
    flex-direction: column;
}
.food-table-item {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.food-table-item__header {
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-align:left;
    font-weight: var(--font-weight-bold);
    border-bottom: calc(1rem/16) solid var(--color-light-grey);
    justify-content: center;
    align-items: center;
    padding-top: calc(8rem/16);
    padding-bottom: calc(12rem/16);
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    grid-gap: calc(15rem/16) calc(5rem/16);
    grid-template-areas:
        ". ."
        "pral-line .";
    @media screen and (min-width: 768px) {
        grid-template-rows: auto;
        padding-top: calc(20rem/16);
        padding-bottom: calc(20rem/16);
        grid-template-columns: 28.1% 1fr 8.1rem;
        grid-template-areas: ". . .";
    }
}
.food-table__vertical-line {
    @media screen and (min-width: 768px) {
        &:before {
            content:'';
            position: absolute;
            height: 100%;
            width: calc(1rem/16);
            background-color: var(--color-light-grey);
            z-index: -1;
            margin-left: 0;
        }
    }
}
.food-table__vertical-line--center {
    @media screen and (min-width: 768px) {
        &:before {
            margin-left: calc(5rem / 16);
        }
    }
}
.food-table__vertical-line--right {
    @media screen and (min-width: 768px) {
        &:before {
            margin-left: calc(20rem / 16);
        }
    }
}
.food-table-item__list {
    border-bottom: calc(1rem/16) solid var(--color-light-grey);
}
.food-table-item__list-item {
    justify-content: center;
    align-items: center;
    padding-top: calc(8rem/16);
    padding-bottom: calc(8rem/16);
    color: var(--color-mid-grey);
    position: relative;
    grid-gap: calc(15rem/16) calc(5rem/16);


    @media screen and (min-width: 768px) {
        grid-template-columns: 28.1% 1fr 8.1rem;
        display: grid;
        padding-top: calc(10rem/16);
        padding-bottom: calc(10rem/16);
    }
}
.food-table-item__list-item:nth-child(odd) {
    &:before {
        z-index: -2;
        content:'';
        position: absolute;
        left:0;
        right:0;
        width: 100%;
        height: 100%;
        background-color: #FDFDFD;
    }
}
.food-table-item__pral-line {
    width: 100%;
    height: calc(5rem/16);
    background: linear-gradient(90deg, rgba(0,115,197,1) 0%, rgba(180,215,237,1) 40%, rgba(250,190,197,1) 60%, rgba(254,78,62,1) 100%);
    clip-path: inset(0% calc(100% - var(--pral-end)) 0% var(--pral-start) round calc(10rem/16));
    display: flex;

    @media screen and (max-width: 767px) {
        grid-area: pral-line;
    }

    @media screen and (min-width: 768px) {
        height: calc(10rem/16);
    }
}
.food-table-item__list-item-pral-line {
    width: 100%;
    height: calc(5rem/16);
    z-index: 2;
    background: linear-gradient(90deg, rgba(0,115,197,1) 0%, rgba(180,215,237,1) 40%, rgba(250,190,197,1) 60%, rgba(254,78,62,1) 100%);
    clip-path: inset(0% calc(100% - (var(--pral-point) + 5px)) 0% calc(var(--pral-point) - 5px) round calc(10rem/16));
    display: flex;

    @media screen and (min-width: 768px) {
        height: calc(10rem/16);
    }
}