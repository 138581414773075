.stretch-link {
    position: relative;
}
.stretch-link a:not(.stretch-link__link),
.stretch-link button:not(.stretch-link__link),
.stretch-link label:not(.stretch-link__link),
.stretch-link input:not(.stretch-link__link),
.stretch-link select:not(.stretch-link__link),
.stretch-link textarea:not(.stretch-link__link),
.stretch-link__interactive {
    position: relative;
    z-index: 2;
}
.stretch-link__link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
}
