.form-control {
    padding: .875rem 1rem;
    color: var(--color-text-default);
    height: calc(54rem / 16);
    cursor: pointer;
    border-radius: 0 !important;
    background-color: #fff;
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    box-shadow: 0 calc(20rem / 16) calc(40rem / 16) 0 rgba(29, 29, 27, 0.1);
    font-size: calc(16rem / 16);
    &:focus {
        box-shadow: 0 calc(20rem / 16) calc(40rem / 16) 0 rgba(29, 29, 27, 0.1);
        border: none!important;
        border-bottom: calc(2rem /16) solid var(--color-primary)!important;
    }
}
.form-control--lg {
    padding: calc(14rem/16) calc(30rem/16);
    font-size: calc(20rem/16);
}

.form-control--xl {
    padding: calc(14rem/16) calc(30rem/16);
    height: calc(60rem /16);
    @media screen and (min-width: 768px){
        font-size: calc(20rem / 16);
    }
}

.form-control:focus, .form-control:disabled {
    background-color: var(--color-white);
    color: var(--color-text-default);
    border: 1px solid;
    outline: none;
}

.form-control[readonly]:focus {
    outline: none;
    box-shadow: none;
}

.form-control.disable {
    pointer-events: none;
}

textarea.form-control {
    height: auto;
}

.form-control__icon {
    position: absolute;
    right: calc(1rem / 16);
    bottom: 0;
    background: var(--color-light-grey);
    color: var(--color-primary);
    width: calc(66rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 13% 100%);
    font-size: calc(22rem / 16);
    pointer-events: none;
    height: calc(54rem /16);
    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
    }
    .has-error ~ & {
        bottom: calc(27rem/16);
        @media screen and (min-width: 768px) {
            bottom: calc(34rem/16);
        }
    }
}

.form-check-label {
    color: var(--color-primary);
    display: flex;
}

.form-check + .form-check {
    margin-top: 1rem;
}

.form-check-input {
    min-height: calc(20rem / 16);
    min-width: calc(20rem / 16);
    margin-right: calc(10rem / 16);
    border-color: var(--color-primary);
}

.form-check-input:focus {
    box-shadow: none;
}

.form-check-input:checked[type=radio] {
    background: #fff;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        height: calc(9rem / 16);
        width: calc(9rem / 16);
        background: var(--color-primary);
        border-radius: 50%;
    }
}

.form-check__checkbox-custom {
    background: #fff;
    position: relative;
    padding: calc(12rem / 16) calc(40rem / 16) calc(12rem / 16) calc(20rem / 16);
    & .form-check-label {
        font-family: var(--font-default-bold);
        font-weight: var(--font-weight-bold);
    }
}

.form-check__checkbox-custom.has-shadow {
    box-shadow: 0 calc(20rem / 16) calc(40rem / 16) 0 rgba(29, 29, 27, 0.1);
}

.form-check__checkbox-custom label {
    color: var(--color-text-default);
    font-size: calc(16rem /16);
}

.form-check__checkbox-custom input {
    position: absolute;
    right: calc(12rem / 16);
    top: calc(50% - calc(15rem /16));
}

.form-check-input[type=checkbox] {
    border-radius: 0;
}

.form-check-input[type=checkbox]:checked + .form-check-label {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
}

select.form-control--lg,
.form-control__icon--big ~ .form-control__icon{
    height: calc(60rem /16);
}

.form-group.has-error .form-control{
    border-bottom: calc(2rem/16) solid var(--color-secondary);
    border-top: none;
    border-right: none;
    border-left: none;
}

.form-label .icon--hide-label{
    display: none;
}
.form-group.has-error .form-label--primary {
    color: var(--color-secondary);
}
.form-label--primary {
    color: var(--color-primary);
    font-size: calc(16rem /16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    @media screen and (min-width: 786px) {
        font-size: calc(20rem /16);
    }
}
.form-control-label__icon {
    width: calc(30rem/16);
    font-size: calc(20rem/16);
    color: var(--color-primary);
}

.has-error .form-control {
    border: 1px solid var(--color-secondary);
}

input[type=checkbox] + .form-check-label {
    color: var(--color-mid-grey);

    > a, >a:hover {
        color: var(--color-primary);
        text-decoration: underline;
    }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: var(--color-text-default);
}

::-moz-placeholder { /* Firefox 19+ */
    color: var(--color-text-default);
}

:-ms-input-placeholder { /* IE 10+ */
    color: var(--color-text-default);
}

:-moz-placeholder { /* Firefox 18- */
    color: var(--color-text-default);
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 3rem var(--color-white) inset !important;
    -webkit-text-fill-color: var(--color-text-default) !important;
    background-image: none !important;
}
