.shipping-item__img {
    max-width: calc(250rem/16);
}
.shipping-item {
    box-shadow: 0 20px 40px 0 rgba(29,29,27,0.1);
    background-color: #fff;
    padding: calc(20rem/16);
    position: relative;
}
.shipping-item__link {
    background-color: var(--color-secondary);
    color: #fff;
    font-size: calc(24rem/16);
    width: calc(30rem/16);
    height: calc(30rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);
    position: absolute;
    right:0;
    top:0;

    @media screen and (min-width: 768px) {
        width: calc(66rem/16);
        height: calc(60rem/16);
    }
}