.language-item-wrapper {
    margin-left: calc(-40rem/16);
    margin-right: calc(-40rem/16);
}
.language-item {
    padding: calc(10rem/16) calc(40rem/16);
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-template-rows: auto;
    gap: 20px 20px;
    grid-template-areas:
    ". .";
    font-size: calc(20rem/16);

    &:hover {
        background-color: var(--color-light-grey);
    }

    @media screen and (min-width: 768px) {
        padding: calc(23rem/16) calc(40rem/16);
    }
}
.language-item__flag {
    margin-top: calc(5rem/16);
}
.language-item__main-link {
    font-weight: var(--font-weight-bold);
}
.language-item__list {
    font-size: calc(18rem/16);
}
.language-item__list-link:hover {
    color: var(--color-primary);
}