.hero {
    background-color: var(--color-primary-lightest-30);
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    gap: calc(15rem / 16) 0;
    grid-template-areas:
    "hero__image"
    "hero__content";

    @media screen and (min-width: 768px) {
        max-width: 100vw;
        grid-template-columns: 51% 49%;
        grid-template-rows: 1fr;
        gap: 0 0;
        grid-template-areas:
        "hero__content hero__image";
    }
}

.hero__content {
    grid-area: hero__content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: calc(15rem / 16) calc(30rem / 16) calc(45rem / 16);
    @media screen and (min-width: 768px) {
        padding: calc(40rem / 16);
    }
    @media screen and (min-width: 1640px) {
        padding-left: calc((100vw - calc(1640rem / 16)) / 2);
    }
}

.hero__image {
    position: relative;
    grid-area: hero__image;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 88%);
    @media screen and (min-width: 768px) {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%);
    }
}

.hero__image__copyright {
    position: absolute;
    right: calc(10rem / 16);
    top: calc(10rem / 16);
    color: #fff;
    font-size: calc(14rem / 16);
    text-shadow: 0 0 10px 0 rgba(29, 29, 27, 0.7);
    @media screen and (min-width: 768px) {
        bottom: calc(20rem / 16);
        right: calc(30rem / 16);
        top: unset;
    }
}

.hero__subtitle {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    font-size: calc(16rem / 16);
    color: var(--color-mid-grey);
    line-height: calc(28 / 20);
    margin-bottom: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}

.hero__title {
    color: var(--color-primary);
    margin-bottom: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(20rem / 16);
    }
}

.hero__recipe-info,
.hero__blog-info {
    color: var(--color-mid-grey);
    display: flex;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    @media screen and (min-width: 768px) {
        margin-bottom: calc(20rem / 16);
    }
}

.hero__blog-info__button {
    @media screen and (max-width: 767px) {
        position: absolute;
        top: calc(-70rem / 16);
        z-index: 1;
    }
}
.hero__recipe-info__button {
    @media screen and (max-width: 767px) {
        position: absolute;
        top: calc(-84rem / 16);
        z-index: 1;
    }
}

.hero__recipe-info--bottom {
    margin-top: calc(36rem / 16);
    @media screen and (min-width: 768px) {
        margin-top: calc(60rem / 16);
    }
}

.hero__recipe-info__button--right {
    left: calc(56rem / 16);
}

.hero__recipe-info__icon {
    color: var(--color-primary);
    font-size: calc(24rem / 16);
}

.hero__recipe-info__text {
    color: var(--color-text-default);
    font-size: calc(14rem / 16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
}

.hero__recipe-info__item {
    display: flex;
    text-align: center;
    padding: 0 calc(4rem / 16);
    flex-direction: column;
}

.hero__recipe-info__item + .hero__recipe-info__item {
    margin-left: calc(40rem / 16);
}

.hero__backlink {
    position: absolute;
    z-index: 2;
    clip-path: polygon(0 0,100% 0,100% 100%,3% 100%);
    padding: calc(3rem/16) calc(15rem/16) calc(3rem/16) calc(20rem/16);
    background-color: var(--color-white);
    color: var(--color-primary);
    font-size: calc(16rem /16);
    line-height: 1;
    bottom: unset;
    top: calc(15rem /16);
    left: calc(15rem /16);

    @media screen and (min-width: 768px) {
        bottom: calc(40rem / 16);
        left: calc(140rem / 16);
        top: unset;
    }

    &:focus,
    &:active,
    &:visited,
    &:hover {
        color: var(--color-primary);
    }

    &:hover .hero__backlink__icon {
        transform: translateX(calc(-4rem /16));
    }

}

.hero__backlink__icon {
    font-size: calc(24rem /16);
    padding: calc(2rem/16) calc(4rem /16) 0 0;
    transition: transform 0.15s ease-in;
}

.hero--recipe {
    position: relative;
}

.hero--recipe__arrow--next {
    clip-path: polygon(30% 0,100% 0,100% 100%,0 100%);
    padding: calc(2rem/16) calc(7rem/16) calc(2rem/16) calc(10rem/16);
    background-color: var(--color-primary);

    right: calc(30rem / 16);
    @media screen and (min-width: 768px) {
        right: calc(40rem / 16);
        padding: calc(10rem/16) calc(15rem/16) calc(10rem/16) calc(20rem/16);
    }
    @media screen and (min-width: 1640px) {
        right: calc((100vw - calc(1640rem / 16)) / 2);
    }
}

.hero--recipe__arrow {
    position: absolute;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    bottom: calc(15rem / 16);
    transition: color 0.2s ease, background-color 0.2s ease;

    @media screen and (min-width: 768px) {
        bottom: calc(40rem / 16);
    }

    &:hover{
        background: var(--color-light-grey);
        & .hero--recipe__arrow-icon {
            background: transparent;
            color: var(--color-primary-dark);
        }
    }
}

.hero--recipe__arrow-icon {
    background: transparent;
    color: #fff;
    font-size: calc(24rem / 16);
    width: auto;
    height: auto;
    transition: opacity 0.25s ease-in-out;
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}

.hero--recipe__arrow--prev {
    clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
    padding: calc(2rem/16) calc(7rem/16) calc(2rem/16) calc(10rem/16);
    background-color: var(--color-primary);

    transform: rotate(180deg);
    right: calc(65rem / 16);
    @media screen and (min-width: 768px) {
        right: calc(102rem / 16);
        padding: calc(10rem/16) calc(15rem/16) calc(10rem/16) calc(20rem/16);
    }
    @media screen and (min-width: 1640px) {
        right: calc(((100vw - calc(1640rem / 16)) / 2) + calc(54rem /16));
    }
}