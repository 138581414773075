.wysiwyg{
    color: var(--color-mid-grey);
}

.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: calc(20rem /16);
    @media screen and(min-width: 768px){
        margin-bottom: calc(40rem /16);
    }
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}

.wysiwyg-area__btn{
    margin-top: calc(20rem /16);
    @media screen and(min-width: 768px){
        margin-top: calc(40rem /16);
    }
}
.wysiwyg-area__content {
    margin-top: calc(16rem /16);
    @media screen and(min-width: 768px){
        margin-top: calc(20rem /16);
    }
}

/* lists */
.wysiwyg ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.wysiwyg ul li {
    padding-left: 1.5rem;
    padding-bottom: 1rem;
    position: relative;
    @media screen and (min-width: 768px){
        padding-left: calc(60rem /16);
    }
}

.wysiwyg ul li:before {
    position: absolute;
    left: 0;
    top: calc(12rem/16);
    content: "";
    width: calc(10rem/16);
    height: calc(1rem/16);
    background-color: var(--color-primary);
    @media screen and (min-width: 768px){
        top: calc(15rem/16);
        width: calc(20rem/16);
    }
}

.wysiwyg ol {
    list-style: none;
    padding: 0;
    margin: 0;;
    counter-reset: list-items-counter;
}
.wysiwyg ol li {
    padding-left: 2.5rem;
    padding-bottom: 1rem;
    position: relative;
    counter-increment: list-items-counter;
    @media screen and (min-width: 768px){
        padding-left: calc(60rem /16);
    }

}
.wysiwyg ol li::before {
    position: absolute;
    left: 0;
    line-height: 1;
    content: counter(list-items-counter);
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    font-size: calc(30rem/16);
    top: calc(-8rem / 16);
    @media screen and (min-width: 768px){
        font-size: calc(42rem/16);
        top: calc(-10rem / 16)
    }
}