.audio-area{
    background-color: var(--color-primary-lightest-30);
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    gap: calc(15rem/16) 0;
    grid-template-areas:
    "audio-area__audio"
    "audio-area__content";

    @media screen and (min-width: 768px){
        max-width: 100vw;
        grid-template-columns: 51% 49%;
        grid-template-rows: 1fr;
        gap: 0 0;
        grid-template-areas:
        "audio-area__content audio-area__audio";
    }
}

.audio-area__content {
    grid-area: audio-area__content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: calc(15rem / 16) calc(30rem /16) calc(45rem /16);
    @media screen and (min-width: 768px){
        padding: calc(40rem/16);
    }
    @media screen and (min-width: 1640px){
        padding-left: calc((100vw - calc(1640rem/16)) / 2);
    }
}

.audio-area__audio {
    grid-area: audio-area__audio;
    background-color: var(--color-primary-lightest-50);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 88%);
    background-image: url("/static/img/sound-wave.svg");
    background-size: contain;
    background-repeat: repeat-x;
    padding: calc(30rem/16);
    background-origin: content-box;
    position: relative;
    min-height: calc(350rem/16);

    @media screen and (min-width: 768px){
        min-height: calc(490rem/16);
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%);
        padding: calc(50rem/16);
    }
}

.audio-area__audio__content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.audio-area__icon {
    font-size: calc(120rem / 16);
    display: block;
    margin-bottom: calc(30rem /16);
    @media screen and (min-width: 768px){
        margin-bottom: calc(40rem /16);
    }
}

.audio-area__player {
    display: block;
    box-shadow: 0 calc(20rem / 16) calc(40rem / 16) 0 rgba(29,29,27,0.1);
    border-radius: calc(30rem / 16);
    & audio {
        width: 100%;
    }
    &::-webkit-media-controls-panel {
        background: #fff;
    }
}
