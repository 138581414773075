.simple-teaser__img-wrapper {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 23% 100%);
}
.simple-teaser__bg {
    position: relative;
    background-color: var(--color-primary-lightest);
    padding-left: calc(60rem/16);
    transition: background-color 0.4s ease;
}
.simple-teaser__icon {
    color: var(--color-primary);
    position: absolute;
    bottom: calc(30rem/16);
    left: calc(30rem/16);
    font-size: calc(24rem/16);
    transition: transform 0.4s ease, color 0.4s ease;
}
.simple-teaser__title {
    font-size: calc(16rem/16);
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    margin-top: calc(10rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
        margin-top: calc(20rem/16);
    }
}
@media screen and (min-width: 768px) {
    .simple-teaser:hover {

        .simple-teaser__icon {
            transform: scale(1.4);
            color: #fff;
        }

        .simple-teaser__bg {
            background-color: var(--color-primary-light);
        }
    }
}
