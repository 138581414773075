.fixed-close-button {
    position: fixed;
    width: calc(50rem / 16);
    height: calc(50rem / 16);
    right: 0;
    top: 0;
    color: var(--color-primary);
    background-color: var(--color-light-grey);
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: calc(12rem / 16);
    z-index: 31;
    transition: color 0.2s ease-in, background-color 0.2s ease-in;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);

    @media screen and (min-width: 768px) {
        width: calc(65rem / 16);
        height: calc(60rem / 16);
        padding: calc(20rem / 16);
    }

    &:hover {
        background-color: var(--color-primary);
        color: #fff;
    }
}
.fixed-close-button__icon {
    font-size: calc(24rem/16);
}