.input-group__btn {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 13% 100%);
    font-size: calc(20rem/16);
    height: calc(60rem/16);
}
.input-group {
    background-color: #fff;
    box-shadow: 0 20px 40px 0 rgba(29,29,27,0.1);
}
.search-input-group {
    background-color: var(--color-light-grey);
    box-shadow: none;
    height: calc(50rem/16);

    @media screen and (min-width: 768px) {
        height: calc(84rem/16);
        padding-left: calc(20rem/16);
    }
}
.search-input-group__icon {
    height: 100%;
}
.search-input {
    box-shadow: none;
    height: 100%;
    padding: calc(10rem/16);
    background-color: var(--color-light-grey);

    @media screen and (min-width: 768px) {
        padding: calc(20rem/16) calc(20rem/16);
    }

    &:focus, &:active {
        background-color: var(--color-light-grey);
        box-shadow: none;
    }
}
.search-input__btn {
    background-color: transparent;
    padding: calc(10rem/16) calc(10rem/16);
    border: none;
    box-shadow: none;
    font-size: calc(26rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(26rem/16);
        padding: calc(20rem/16) calc(40rem/16);
    }
}