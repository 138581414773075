html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
    word-break: break-word;
    @media screen and (max-width: 767px) {
        font-size: var(--font-size-default--mobile);
    }
}
.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
}

h1, .h1 {
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    font-size: calc(36rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(56rem/16);
        line-height: 1.1;
    }
}
h2, .h2 {
    font-size: calc(30rem/16);
}
h3, .h3 {
    font-size: calc(24rem/16);
}
h4, .h4 {
    font-size: calc(20rem/16);
    text-transform: uppercase;
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
}
h5, .h5 {
    font-size: calc(18rem/16);
}
h6, .h6 {
    font-size: 1em;
}

table{
    word-break: initial;
}