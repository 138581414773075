.media-area__image {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 88%);
}

.media-area__media {
    margin-top: calc(40rem /16);
    @media screen and (min-width: 768px){
        margin-top: calc(80rem /16);
    }
}

.media-area__copyright {
    color: var(--color-light-grey--dark);
    font-size: clamp(calc(14rem / 16), 1.5vw, calc(20rem / 16));
    margin-top: calc(4rem / 16);
    @media screen and (min-width: 768px){
        max-width: calc(380rem /16);
    }
}