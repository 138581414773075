.btn {
    @media screen and (max-width: 767px) {
        font-size: calc(16rem / 16);
    }
}

.btn-secondary {
    color: #fff;

    &:disabled{

        background-color: var(--color-secondary-disabled);
        color: #fff;
    }
}

.btn-icon {
    padding: 0 0 0 calc(15rem / 16);
    height: calc(60rem / 16);
    align-items: center;
    line-height: 1;
    text-align:left;
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 768px) {
        padding: 0 0 0 calc(30rem / 16);
    }
}

.btn-light {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: var(--color-secondary);
    box-shadow: 0 calc(20rem/16) calc(40rem/16) 0 rgba(29,29,27,0.1);
    background-color: #fff;
    transition: color 0.2s ease, background-color 0.2s ease;

    &:hover {
        background-color: var(--color-secondary);
        color: #fff;
    }
}

.btn-outline-primary {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
    transition: color 0.2s ease, background-color 0.2s ease;
    background-color: transparent;
    border: calc(2rem / 16) solid var(--color-primary);

    &:active,
    &:focus,
    &:hover {
        background-color: var(--color-primary);
        color: #fff;
        border: calc(2rem / 16) solid var(--color-primary);
        outline: none;
        box-shadow: none;
    }
}


.btn-outline-primary {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
    transition: color 0.2s ease, background-color 0.2s ease;
    background-color: transparent;
    border: calc(2rem / 16) solid var(--color-primary);

    &:active,
    &:focus,
    &:hover {
        background-color: var(--color-primary);
        color: #fff;
        border: calc(2rem / 16) solid var(--color-primary);
        outline: none;
        box-shadow: none;
    }
}


.btn-lg {
    border-radius: 0;
    font-size: calc(16rem / 16);
    @media screen and (min-width: 768px) {
        padding: calc(12rem / 16) calc(64rem / 16);
    }
}
.btn-md {
    border-radius: 0;
    font-size: calc(16rem / 16);
    letter-spacing: 0.5px;
    @media screen and (min-width: 768px) {
        padding: calc(12rem / 16) calc(12rem / 16);
    }
}
.btn-icon__icon {
    height: calc(60rem / 16);
    width: calc(66rem / 16);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: calc(25rem / 16);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 13% 100%);
    font-size: calc(22rem / 16);
    margin-right: calc(-1rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
    }
}

.btn-icon--secondary .btn-icon__icon {
    background-color: var(--color-secondary);
    color: #fff;
}

.btn-icon--secondary ~ .btn-icon__icon {
    background-color: var(--color-secondary);
    color: #fff;
}

.btn-icon--light-grey {
    color: var(--color-mid-grey);

    &:hover {
        background-color: var(--color-primary);
    }
}
.btn-icon--light-grey .btn-icon__icon {
    background-color: var(--color-light-grey);
    color: var(--color-primary);
}

.btn-icon--primary {
    color: var(--color-text-default);
    background-color: #fff;

    &:hover {
        background-color: var(--color-primary);
    }
}
.btn-icon--primary .btn-icon__icon {
    color: var(--color-light-grey);
    background-color: var(--color-primary);
}

.btn-icon--pinterest {
    color: var(--color-text-default);
    background-color: #fff;

    &:hover {
        background-color: #E60023;
    }
}
.btn-icon--pinterest .btn-icon__icon {
    color: var(--color-light-grey);
    background-color: #E60023;
}

.btn-light-grey {
    background-color: var(--color-light-grey);
    color: var(--color-primary);
}
.btn-primary {
    background-color: var(--color-primary);
    color: #fff;
    transition: background-color 0.2s ease-in, color 0.2s ease-in;
    &:visited,
    &:active,
    &:focus,
    &:hover {
        background-color: var(--color-light-grey);
        color: var(--color-primary);
    }
}

.btn-outline--dark {
        background-color: var(--color-primary);
        color: var(--color-dark);
        transition: background-color 0.2s ease-in, color 0.2s ease-in;
        font-family: var(--font-default-bold);
         font-weight: var(--font-weight-bold);

        &:visited,
        &:active,
        &:focus,
        {
            background-color: var(--color-light-grey);
            color: var(--color-dark);
            border: calc(2rem / 16) solid var(--color-dark);
            outline: none;
            box-shadow: none;
        }
        &:hover {
            background-color: var(--color-primary);
            color: var(--color-white);
            border: calc(2rem / 16) solid var(--color-primary);
            outline: none;
            box-shadow: none;
        }

  ;
    border: calc(2rem / 16) solid var(--color-primary);

    }
.btn--icon-only {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(24rem /16);
    clip-path: polygon(0 0, 80% 0%, 100% 100%, 0% 100%);
    padding: calc(15rem /16) calc(22rem /16) calc(15rem /16) calc(15rem /16);
}
.btn--icon-only--right {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);
    padding: calc(15rem /16) calc(15rem /16) calc(15rem /16) calc(22rem /16) ;
}
.btn-radio {
    border: 1px solid #fff;
    color:#fff;
    min-width: calc(95rem/16);
}
.btn-radio--no-styling{
    position: fixed;
    opacity: 0;
    pointer-events: none;
}
.btn-check:checked + .btn-radio, .btn-check.checked + .btn-radio {
    background-color: #fff;
    color: var(--color-primary);
}
.btn-radio--dark {
    border: 1px solid var(--color-dark);
    color:var(--color-dark);
    min-width: calc(95rem/16);
}


.btn-check:checked + .btn-radio--dark {
    background-color: var(--color-dark);
    color: #fff;
}



@media screen and (max-width:  767px) {
    .btn-radio--dark-mobile {
        border: 1px solid var(--color-dark);
        color:var(--color-dark);
        min-width: calc(95rem/16);
    }
    .btn-check:checked + .btn-radio--dark-mobile {
        background-color: var(--color-dark);
        color: #fff;
    }
    .btn-block--mobile {
        width: 100%;
    }
}