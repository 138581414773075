.pral-scala {
    position: relative;

    @media screen and (min-width: 768px) {
        &:before {
            content:'';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: calc(4rem/16);
            background: linear-gradient(90deg, rgba(0,115,197,1) 0%, rgb(192, 178, 215) 50%, rgba(254,78,62,1) 100%);
        }
    }
}
.pral-scala-text__left {
    color: var(--color-primary);
}
.pral-scala-text__right {
    color: var(--color-secondary);
    position: relative;

    @media screen and (max-width: 767px) {
        &:before {
            content:'';
            position: absolute;
            top: calc(-8rem/16);
            left:0;
            width: 100%;
            height: calc(4rem/16);
            background: linear-gradient(90deg, rgba(0,115,197,1) 0%, rgb(192, 178, 215) 50%, rgba(254,78,62,1) 100%);
        }
    }
}
.pral-scala-text {
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: calc(15rem/16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    font-size: calc(16rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}