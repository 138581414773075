.content-teaser-area {
    padding: calc(60rem /16) 0;
    background-color: var(--color-primary-lightest);
    overflow: hidden;
    @media screen and(min-width:768px){
        padding: calc(80rem /16) 0;
    }
}
.content-teaser-area.bg-white{
    padding: 0;
}
.content-teaser-list {
    padding-left: calc(30rem /16);
    padding-right: calc(30rem /16);
    padding-bottom: calc(48rem / 16);
    @media screen and (min-width: 1700px){
        padding-left: calc((100vw - (var(--container-max-width))) /2);
        padding-right: calc((100vw - (var(--container-max-width))) /2);
    }
}
.content-teaser-list__item {
    max-width: calc(520rem/16);
    width: 100%;
    padding-left: calc(8rem/16);
    padding-right: calc(8rem/16);
    scroll-snap-align: center;
    @media screen and(min-width:768px){
        padding-left: calc(20rem/16);
        padding-right: calc(20rem/16);
    }
}
.content-teaser-list {
    margin-left: calc(-8rem/16);
    margin-right: calc(-8rem/16);
    @media screen and(min-width:768px){
        margin-left: calc(-20rem/16);
        margin-right: calc(-20rem/16);
    }
}

.content-teaser{
    transition: box-shadow 0.25s ease-in-out;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 calc(16rem /16) calc(32rem /16) 0 rgba(29,29,27,0.1);
    overflow: hidden;
    &:hover .content-teaser__title {
        color: var(--color-primary);
    }
    &:hover {
        box-shadow: 0 calc(16rem /16) calc(32rem /16) 0 rgba(29,29,27,0.2);
    }
    &:hover .content-teaser__image {
        clip-path: polygon(0 0, 100% 0%, 100% 95%, 0 83%);
    }

}

.content-teaser__image {
    transition: clip-path 0.2s ease-in;
    position: relative;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 88%);
}

.content-teaser__content {
    padding: 0 calc(30rem /16) calc(30rem /16) calc(30rem /16);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @media screen and (min-width: 768px){
        padding: 0 calc(40rem /16) calc(40rem /16) calc(40rem /16);
    }
}

.content-teaser__title {
    color: var(--color-primary);
    text-transform: uppercase;
    font-size: calc(20rem /16);
    font-weight: var(--font-weight-bold);
    font-family: var(--font-bold);
}

.content-teaser__text{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: calc(10rem /16);
    @media screen and (min-width:768px){
        margin-top: calc(20rem /16);
    }
}

.content-teaser__image {
    position: relative;
}

.content-teaser__badge {
    position: absolute;
    z-index: 1;
    top: calc(20rem /16);
    font-size: calc(14rem / 16);
    padding: calc(5rem /16) calc(20rem /16);
}
.content-teaser__badge--left{
    left: 0;
    background-color: white;
    color: black;
    mix-blend-mode: screen;
    opacity: .7;
    clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
}
.content-teaser__badge--right {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
    right: 0;
    background-color: var(--color-primary);
    color: #fff;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
}

.content-teaser__info{
    display: flex;
    align-items: center;
    font-size: calc(16rem /16);
    line-height: 1;
    color: var(--color-mid-grey);
    margin-bottom: calc(10rem /16);
    @media screen and (min-width:768px){
        margin-bottom: calc(20rem /16);
    }
}

.content-teaser__info__icon{
    font-size: calc(20rem /16);
    margin-right: calc(10rem /16);
}