.product-selection-bar {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 10;
    background-color: var(--color-light-grey);
    flex-direction: column;
    align-items: center;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    @media screen and (max-width: 767px) {
        grid-template-rows: auto auto;
        grid-template-columns: auto;
    }
}

.product-selection-bar__sticky-cta {

    grid-row: 2 / 3;
    width: auto;
    background-color: var(--color-light-grey);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: calc(20rem/16) calc(40rem/16);
    position: relative;
    @media screen and (max-width: 767px) {
        grid-template-columns: 100%;;
        grid-template-rows:auto auto;
        display: grid;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: center;
        grid-row: 2 / 3;
        border-radius: calc(8rem/16);
        position: relative;
        padding: calc(17rem/16) calc(15rem/16);
        font-size: calc(14rem/16);
    }
    @media screen and (min-width: 768px) {
        inset: auto 0 0 0;
        z-index: 20;
        flex-direction: row;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        height: auto;
    }

    @media screen and (max-width: 320px) {
        width: auto;
        height: auto;
    }
}

.content-block:has(.product-hero__media)~.selection-bar{
    display:none;
}

.product-selection-bar__dropdown{
    display: flex;
    flex-direction: row;
    grid-row: 1 / 2;
    justify-content: flex-start;
    padding: calc(20rem/16) calc(40rem/16);
    column-gap: 100px;
    @media screen and (max-width: 767px) {
        overflow-y: auto;
        max-height: calc(100dvh - 187px);
        height: calc(100dvh - 187px);
        padding: calc(17rem/16) calc(15rem/16);
        column-gap: 0;
        flex-direction: column;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        column-gap: calc(20rem/16);
        flex-direction: row;
    }
}

.co ~.product-selection-bar__sticky-cta .product-selection-bar__btn-icon{
    transform: rotate(180deg);
    transition: transform 300ms ease;
}

#productCollapse.show.product-selection-bar__btn{
    margin-bottom: 0;
}

 label:has(.product-selection-bar__input[type="radio"]:checked ) {
    font-weight:bold;
    transition: font-weight 200ms ease-in;
}

label:has(.product-selection-bar__input[type="radio"]:hover ) {
    color: var(--color-primary);
    transition: color 200ms ease-in;
    cursor: pointer;
}

.product-selection-bar__infos{
    align-items: center;
    display: flex;
    margin-right: calc(20rem/16);
    grid-row: 1/2;
    color: #0073C5;
    &:before{
        top: unset;
    }
    @media screen and (max-width: 767px) {
        margin-bottom: calc(16rem/16);
        left: calc(-5rem/16);
    }
}

.product-selection-bar__column{
    flex-direction: column;
    display: flex;
    align-items: start;
    row-gap: calc(15rem/16);
    @media screen and (max-width: 1430px) {
        flex: 50%;
        margin-bottom: calc(15rem/16);
    }
    
    @media screen and (max-width: 767px) {
        flex:unset;
    }
}

.product-selection-bar__btn {
    grid: 1/2;
    font-size: calc(20rem/16);
    line-height: calc(28/24);
    font-weight: 700;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    width: calc(242rem/16);
    height: calc(48rem/16);

    &:hover{
    color: var(--color-primary);
    }
    @media screen and (max-width: 767px) {
        position: relative;
        width: 100%;
        display: flex;
        margin-bottom: calc(15rem/16);
        flex-direction: row;
        justify-content: space-between;
        font-size: calc(16rem/16);

    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
}

.product-selection-bar__ctas {
    grid: 2/3;
    display: flex;
    column-gap: calc(10rem/16);
    @media screen and (max-width: 767px) {
        inset: auto 0 0 0;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
        align-items: center;
        justify-content: center;
        column-gap: calc(15rem/16);
        z-index: 1000;
        background: var(--color-light-grey);
    }

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        flex-direction: column;
        row-gap: calc(10rem/16);
        align-self: start;
    }
}

.product-selection-bar__cta{
    font-size: calc(14rem/16);
    letter-spacing: calc(0.5rem/16);
    line-height: calc(20rem/16);
    @media screen and (min-width: 768px) {
        width: calc(240rem/16);
        height: calc(48rem/16);
    }
    @media screen and (min-width: 378px) and (max-width: 767px) {
        width: calc(165rem/16);
        height: auto;
    }
    @media screen and (max-width: 377px) {
        width: auto;
        height: auto;
    }
}

.btn-outline--dark.product-selection-bar__btn {
    padding: calc(10rem/16) calc(20rem/16);
}

.product-selection-bar__btn:has(.product-selection-bar__btn-no-styling){
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
        margin-bottom:0 !important;
    }
    padding:0;
    min-width:0;
    border: none;
    background-color: transparent;
    box-shadow: none;
    color: #545463;
}

.product-selection-bar__btn-icon {
    translate: calc(8rem/16) calc(0rem/16);
    color: var(--color-dark);
    background-color: transparent;
    font-size: calc(32rem/16);

}

a:hover.btn-outline--dark:hover .product-selection-bar__btn-icon {
    color: var(--color-white);
}

.product-selection-bar__infos-arrow{
    font-size: calc(24rem/16);
    color: #0073C5;
    translate: calc(-4rem/16) calc(0rem/16);
}

.selection-bar .sticky-button{
    position: relative !important;
}


