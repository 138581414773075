.copy-to-clipboard{
    position: relative;
}
.copy-to-clipboard__icon{
    position: relative;

    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        content: var(--icon-checkmark);
        opacity: 0;
        transition: opacity 0.5s ease-in;
    }
    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        opacity: 1;
        transition: opacity 0.5s ease-in;
    }
}

.copy-to-clipboard__icon.success {
    animation: success 3s ease-in-out;
    &:before {
        opacity: 0;
    }
    &:after {
        opacity: 1;
    }
}

@keyframes success {
    0% {
       background-color: var(--color-primary);
    }
    20% {
        background-color: green;
    }
    80% {
        background-color: green;
    }
    100% {
        background-color: var(--color-primary);
    }
}