.footer {
    position: relative;
    z-index: 3;
    margin-bottom: calc(60rem/16);
}
.footer-meta {
    padding-top: calc(20rem/16);

    @media screen and (min-width: 768px) {
        padding-bottom: calc(20rem/16);
        background-color: var(--color-light-grey--dark);
    }
}
.footer-meta__social-list {
    margin-left: calc(-30rem/16);
    margin-right: calc(-30rem/16);
    background-color: var(--color-light-grey--dark);
    padding-top: calc(15rem/16);
    padding-bottom: calc(15rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-left: calc(-20rem/16);
        margin-right: calc(-20rem/16);
        padding-top: 0;
        margin-top: 0;
        padding-bottom: 0;
        justify-content: flex-start;
        background-color: transparent;
    }
}
.footer-meta__social-item {
    color: #fff;
    font-size: calc(32rem/16);
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);
}
.footer-main {
    background-color: var(--color-light-grey);
    padding-top: calc(20rem/16);
    padding-bottom: calc(25rem/16);

    @media screen and (min-width: 768px) {
        padding-bottom: calc(60rem/16);
    }
}
.footer-main__title {
    color: var(--color-primary);
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    margin-bottom: calc(20rem/16);
}
.footer-additional {
    position: relative;
    height: calc(140rem/16);
    display: flex;
    flex-direction: column;
    background-color: var(--color-light-grey);
    align-items: center;

    @media screen and (min-width: 768px) {
        background-color: #fff;
        justify-content: center;
    }
}
.footer-additional__copyright {
    color: var(--color-primary);
    display: flex;
    align-items: center;
}
.footer-additional__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
    }
}
.footer-main__link {
    display: block;
    margin-bottom: calc(20rem/16);
    @media screen and (min-width: 768px) and (max-width: 1399px) {
        font-size: calc(14rem/16);
    }
}
.footer-main__contact-list-item {
    display: flex;
    @media screen and (min-width: 768px) and (max-width: 1399px) {
        font-size: calc(14rem/16);
    }
}
.footer-main__contact-list-icon {
    color: var(--color-primary);
    width: calc(25rem/16);
    margin-right: calc(10rem/16);
    font-size: calc(25rem/16);
}
.footer__brand {
    width: calc(100rem/16);

    @media screen and (min-width: 768px) {
        width: calc(150rem/16);
    }
}
.footer-main__link-additional {
    @media screen and (max-width: 767px) {
        background-color: #fff;
        box-shadow: 0 .625rem 1.25rem 0 rgba(29,29,27,.1);
        font-size: .875rem;
        line-height: 1;
        font-weight: var(--font-weight-bold);
        color: var(--color-mid-grey);
        display: flex;
        height: calc(54rem/16);
        align-items: center;
        justify-content: space-between;
        padding-left: calc(20rem/16);
    }
}
.footer-main__link-additional-icon {
    background-color: var(--color-light-grey);
    clip-path: polygon(0 0,100% 0,100% 100%,15% 100%);
    width: calc(75rem/16);
    height: 100%;
    color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(20rem/16);
}
.footer-main__accordion-title {
    @media screen and (max-width: 767px) {
        margin-bottom: calc(20rem/16);
    }
}

.footer-small-print{
    margin-top: calc(20rem/16);
    margin-bottom: calc(20rem/16);
}

.footer-small-print__item {
    color: var(--color-text-muted);
    font-size: calc(12rem/16);
    line-height: calc(20/12);
    display: block;
    @media screen and (max-width: 767px) {
        font-size: calc(10rem/16);
        line-height: calc(16/10);
    }
}