.sticky-button {
    position: fixed;
    z-index: 9;
    display: flex;
    @media screen and (max-width: 767px) {
        bottom: 0;
        right:0;
        width: 100%;
        left:0;
        display: none;
    }
}

.sticky-button__wrapper {
    z-index: 9;
    margin-bottom: calc(20rem/16);
    margin-right: calc(20rem/16);
    display: flex;
}

.sticky-button__btn  {
    transition: transform 300ms ease;
    transform: translateX(0);
    right: -35px;
    position: relative;
    box-shadow: 0 1.25rem 2.5rem 0 rgba(29, 29, 27, 0.11);
    @media (max-width: 767px) {
        background-color: var(--color-secondary);
        color: white;

        &:active {
            background-color: var(--color-secondary);
            color: white;
        }
    }
}


.sticky-button--collapse ~.btn-icon__icon{
    transition: border-top-left-radius 300ms ease, border-bottom-left-radius 300ms ease;
    padding: calc(20rem/16);
}

.btn-icon.sticky-button__btn  {
    padding-right: calc(30rem/16);
}

.sticky-button--mobile.sticky-button{
    z-index: 1;
    position: relative;
    margin-top: calc(50rem/16);
    justify-content: center;
    @media (max-width: 767px) {
        padding: 0;
        padding-bottom: calc(30rem/16);
    }
}

.sticky-button--mobile .sticky-button--collapse .btn__icon{
    border-radius: calc(0rem / 16) calc(6rem / 16) calc(6rem / 16) calc(0rem / 16);
}

.sticky-button--mobile .sticky-button--collapse{
    overflow: unset;
    width: 100%;
}

.sticky-button--mobile .sticky-button--collapse{
    position: relative
}

