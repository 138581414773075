.sb-result-slide-wrapper {
    background-color: var(--color-primary-lightest-30);
    position: relative;
}
.sb-result-slide {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-template-areas:
    "sb-result-slide__content"
    "sb-result-slide__main";

    @media screen and (min-width: 768px){
        min-height: calc(490rem/16);
        max-width: 100vw;
        grid-template-columns: 51% 49%;
        grid-template-rows: 1fr;
        gap: 0 0;
        grid-template-areas:
        "sb-result-slide__content sb-result-slide__main";
    }
}
.sb-result-slide__content {
    grid-area: sb-result-slide__content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: calc(30rem/16);
    padding-right: calc(30rem/16);
    padding-bottom: calc(30rem/16);
    position: relative;

    @media screen and (max-width: 767px) {
        padding-top: calc(78rem/16);
        text-align: center;
    }

    @media screen and (min-width: 768px) {
        background-color: transparent;
    }

    @media screen and (min-width: 1800px) {
        padding: 0 calc(20rem/16) calc(20rem/16) calc(50vw - var(--container-max-width)/2);
    }
}
.sb-result-slide__main {
    overflow:  hidden;
    grid-area: sb-result-slide__main;
    position: relative;
    padding-left: calc(100rem/16);
    padding-right: calc(100rem/16);
    @media screen and (max-width: 767px) {
        padding-bottom: calc(48rem/16);
        padding-left: calc(30rem/16);
        padding-right: calc(30rem/16);
    }
}
.sb-result-slide__main:before {
    content:'';
    position: absolute;
    left: 0;
    width: 100%;
    height: 40%;
    bottom: 0;
    background-color: #fff;
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 12% 100%);

    @media screen and (min-width: 768px) {
        width: 130%;
        height: 100%;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 12% 100%);
    }
}
.sb-result-slide__text-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 768px) {
        height: 100%;
    }
}
.sb-result-slide__dashboard-pointer {
    position: absolute;
    left: 50%;
    top: 42%;
    transform-origin: center;
    transform: translateX(-50%) rotate(-195deg);

    @media screen and (max-width: 767px) {
        top: 7%;
    }
}
.sb-result-slide__dashboard-pointer-path {
    fill: var(--color-alkaline-dark);
}
.sb-result-slide__dashboard-pointer-level {
    animation-duration: 2.5s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(.52,1.5,0.8,1);
    animation-name: animateLevel;
    max-width: 100%;
    width: 100%;

    @media screen and (max-width: 1400px) {
        max-width: calc(250rem/16);
    }
    @media screen and (max-width: 1199px) {
        max-width: calc(200rem/16);
    }
}
.sb-result-slide__dashboard-pointer-path {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-name: animateColorLevel;
}
@keyframes animateLevel {
    0% {
        transform: translateX(-50%) rotate(-195deg);
    }
    100% {
        transform: translateX(-50%) rotate(calc(-195deg + (207deg/16 * (var(--level)))));
    }
}

@keyframes animateColorLevel {
    0% {
        fill: var(--color-alkaline-dark);
    }
    100% {
        fill: var(--pointer-color);
    }
}
.sb-result-slide__dashboard-legend--left {
    font-size: calc(18rem/16);
    color: var(--color-alkaline-dark);
    padding-left: calc(10rem/16);
}
.sb-result-slide__dashboard-legend--right {
    color: var(--color-acidic-dark);
    font-size: calc(18rem/16);
    padding-right: calc(10rem/16);
}
.sb-result-slide__dashboard-legend {
    padding-top: calc(16rem/16);
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
        padding-top: calc(5rem/16);
    }
}