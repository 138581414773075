.filter-overlay {
    position: fixed;
    inset: 0;
    background: #fff;
    z-index: 1002;
    padding-top: calc(70rem /16);
    transform: translateX(100vw);
    transition: transform .3s ease-in;
}

.filter-overlay.is-open {
    transform: translateX(0);
}

.filter-overlay__container {
    height: 100%;
    padding-bottom: calc(150rem/16);
    overflow-y: auto;
    overflow-x: hidden;
}

.filter-overlay__close{
    position: absolute;
    right: 0;
    top: 0;
    color: #000;
    background-color: var(--color-light-grey);
    width: calc(60rem / 16);
    height: calc(60rem / 16);
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: calc(12rem / 16);
    z-index: 31;
    transition: color 0.2s ease-in, background-color 0.2s ease-in;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
    font-size: calc(24rem /16);
}

.filter-overlay__title{
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: var(--color-text-default);
    margin-bottom: calc(15rem /16);
    font-size: calc(26rem /16);
}

.filter-overlay__title--small{
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: var(--color-mid-grey);
    margin-bottom: calc(15rem /16);
    font-size: calc(16rem /16);
}

.filter-overlay__buttons {
    position: absolute;
    bottom: 1rem;
    right: 0;
    left: 0;
    background-color: #fff;
}