.pagination-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(75rem/16);
}

.pagination{
    display: inline-flex;
}

.page-item{
    position: relative;
}
.page-item--arrow.disabled .page-link {
    color: var(--color-primary-lightest);
}
.page-item:not(.page-item--arrow) .page-link {
    position: relative;
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    border: 0;
    width: calc(40rem/16);
    height: calc(40rem/16);
    background-color: #fff;
    color: var(--color-mid-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .375rem;
    border-radius: 0;
    transition: background-color 0.2s ease-in, color 0.2s ease-in;
}

.page-item.active .page-link,
.page-item .page-link:hover{
    background-color: var(--color-primary);
    color: #fff;
}

.page-item+.page-item{
    margin-left: calc(15rem/16);
}

.page-item--arrow {
    color: var(--color-primary);
    width: calc(24rem/16);
    height: calc(24rem/16);
    margin: 0 calc(15rem /16);
}
.page-link__prev,
.page-link__next {
    display: block;
    background-color: transparent;
    font-size: calc(24rem /16)
}