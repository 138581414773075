.lg-backdrop {
    background-color: var(--color-primary-lightest);
}
.lg-toolbar,
.lg-sub-html {
    background-color: var(--color-primary-lightest);
    color: var(--color-primary);
}
.lg-icon.lg-prev,
.lg-icon.lg-next,
.lg-icon.lg-close {
    color: var(--color-primary);
    background-color: var(--color-light);
    border-radius: 0;
    height: calc(30rem/16);
    width: calc(30rem/16);
    @media screen and (min-width: 768px){
        height: calc(50rem/16);
        width: calc(50rem/16);
    }
}
.lg-icon.lg-prev,
.lg-icon.lg-next {

}
.lg-icon.lg-prev:hover,
.lg-icon.lg-next:hover,
.lg-icon.lg-close:hover {
    background-color: var(--color-primary);
    color: #fff;
}
.lg-icon.lg-prev:after {
    content: var(--icon-arrow-left);
    font-family: iconfont;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 767px){
        font-size: calc(20rem/16);
    }
}
.lg-icon.lg-next:before {
    content: var(--icon-arrow-right);
    font-family: iconfont;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 767px){
        font-size: calc(20rem/16);
    }
}
.lg-icon.lg-close:after {
    content: var(--icon-close);
    font-family: iconfont;
    @media screen and (max-width: 767px){
        font-size: calc(20rem/16);
    }
}
.lg-icon.lg-close{
    @media screen and (max-width: 767px){
        padding: calc(2rem/16);
    }
}