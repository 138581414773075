.accordion .card {
    background-color: #fff;
    border: none;
    margin-bottom: calc(20rem/16);
    border-radius: 0;
    box-shadow: 0 1.25rem 3.125rem 0 rgba(1,25,38,0.1);
}
.accordion__header-link {
    width: 100%;
    font-size: calc(14rem/16);
    line-height: 1;
    padding: calc(20rem/16);
    color: var(--color-mid-grey);
    border: none;
    background: #fff;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.2s ease-in;
    &:not(.collapsed){
        background-color: var(--color-light-grey);
    }

    h2 {
        font-size: calc(17rem/16);
        font-family: var(--font-default-bold);
        font-weight: var(--font-weight-bold);
    }

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
        padding: calc(20rem/16) calc(50rem/16) calc(20rem/16) calc(30rem/16);
    }
}
.accordion__icon__wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 100%);
    background-color: var(--color-light-grey);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}
.accordion__icon {
    font-size: calc(20rem/16);
    transition: transform 200ms ease-in;
    color: var(--color-mid-grey);
}
.accordion .accordion__header-link:not(.collapsed) .icon {
    transform: rotate(180deg);
    color: var(--color-primary);
}
.accordion .card-header {
    border: none;
    background-color: #fff;

}
.accordion__body {
    border: none;
    padding: calc(20rem /16);
    font-size: calc(14rem/16);
    @media screen and (min-width: 768px){
        padding: calc(40rem /16);
        font-size: calc(18rem/16);
    }
}