.checkbox-multiple-select{
    position: relative;
    width: 100%;
}

.checkbox-multiple-select label{
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkbox-multiple-select:before,
.checkbox-multiple-select:after {
    content: " ";
    display: table;
}

.checkbox-multiple-select:after {
    clear: both;
}

.checkbox-multiple-select__label{
    padding: calc(10rem/16) calc(8rem/16);
    @media screen and (min-width: 1200px){
        padding: calc(10rem/16) calc(20rem/16);
    }
}

.checkbox-multiple-select__label:hover{
    background-color: var(--color-light-grey);
}

.checkbox-multiple-select__input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.checkbox-multiple-select__text {
    display: block;
    overflow: hidden;
    word-break: break-all;
    font-size: calc(16rem/16);
}
.checkbox-multiple-select__icon__wrapper {
    height: 1.25rem;
    width: 1.25rem;
    border: 1px solid var(--color-primary);
    position: relative;
    margin-right: .75rem;
}
.checkbox-multiple-select__icon {
    visibility: hidden;
    color: var(--color-primary);
    font-size: calc(10rem/16);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.checkbox-multiple-select__input:checked ~ .checkbox-multiple-select__icon__wrapper{
    background-color: var(--color-primary);
}
.checkbox-multiple-select__input:checked ~ .checkbox-multiple-select__icon__wrapper .checkbox-multiple-select__icon {
    visibility: visible;
    color: var(--color-white);
}

.checkbox-multiple-select__input:checked ~ .checkbox-multiple-select__icon__wrapper ~ .checkbox-multiple-select__text{
    font-family: var(--font-default-bold);
}

.checkbox-multiple-select__count{
    color: var(--color-grey);
    font-size: calc(10rem/16);
    letter-spacing: calc(0.56rem/16);
    margin-left: calc(3rem/16);
}


