.recipe-preparation {
    position: relative;
    z-index: 1;
    padding-bottom: calc(60rem /16);

    @media screen and (min-width:768px){
        padding: calc(200rem /16) 0 calc(120rem /16);
    }

    &::after {
        position: absolute;
        content: "";
        inset: 0;
        background-color: var(--color-primary-lightest);
        z-index: -1;
        clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100%);
        @media screen and (max-width:767px){
            top: calc(-120rem /16);
            clip-path: polygon(0 0, 100% 3%, 100% 100%, 0% 100%);
        }
    }
}

.recipe-preparation__item{
    color: var(--color-primary);
    padding-top: calc(60rem /16);
    @media screen and (min-width: 768px){
        padding-top: calc(80rem /16);
    }
}

.recipe-preparation__step {
    padding-left: 1.5rem;
    padding-bottom: calc(4rem /16);
    position: relative;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    font-size: calc(20rem /16);

    @media screen and (min-width: 768px){
        padding-left: calc(60rem /16);
    }

    &:before {
        position: absolute;
        left: 0;
        top: calc(12rem/16);
        content: "";
        width: calc(10rem/16);
        height: calc(1rem/16);
        background-color: var(--color-primary);
        @media screen and (min-width: 768px){
            top: calc(15rem/16);
            width: calc(20rem/16);
        }
    }
}

.recipe-preparation__content{
    font-size: calc(26rem /16);
    margin-top: calc(8rem /16);
    @media screen and (min-width:768px){
        font-size: clamp(calc(26rem /16), 2.25vw, calc(36rem /16));
    }
}