.product-teaser-area {
    background-color: var(--color-primary-lightest);
    overflow: hidden;
    padding-top: calc(20rem/16);
    padding-bottom: calc(20rem/16)
}
.product-teaser-list__item {
    max-width: calc(220rem/16);
    width: 100%;
    padding-left: calc(7rem/16);
    padding-right: calc(7rem/16);
    scroll-snap-align: center;
    @media screen and (min-width: 768px) {
        max-width: calc(380rem/16);
        padding-left: calc(20rem/16);
        padding-right: calc(20rem/16);
    }
}
.product-teaser-list {
    padding-left: calc(23rem /16);
    padding-right: calc(23rem /16);
    @media screen and (min-width: 768px) {
        padding-left: calc(10rem /16);
        padding-right: calc(10rem /16);
    }
    @media screen and (min-width: 1640px){
        padding-left: calc(((100vw - (var(--container-max-width) + var(--container-padding))) / 2) - calc(20rem /16));
        padding-right: calc(((100vw - (var(--container-max-width) + var(--container-padding))) / 2) - calc(20rem /16));
    }
}

.product-teaser {
    position: relative;
    color: var(--color-text-default);
    height: 100%;
    overflow: hidden;

    &:after {
        content:'';
        position: absolute;
        width: 100%;
        bottom: calc(-3rem/16);
        height: calc(5rem/16);
        left: 0;
        background-color: var(--product-color);
        transition: transform 0.2s ease;

        @media screen and (max-width: 767px) {
            transform: translateY(calc(-3rem/16));
        }
    }

    &:before {
        content:'';
        position: absolute;
        left:0;
        width: 100%;
        height: 85%;
        bottom: 0;
        background-color:#fff;
        z-index: 0;
        clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100%);

        @media screen and (min-width: 768px) {
            height: 70%;
            clip-path: polygon(0 0, 100% 19%, 100% 100%, 0% 100%);
        }
    }

    &.product-teaser--grey:before {
        background-color: var(--color-light-grey);
    }
}
.product-teaser:hover:after {
    transform: translateY(calc(-3rem/16));
}
.product-teaser__inner {
    padding: calc(15rem/16);

    @media screen and (min-width: 1200px) {
        padding: calc(40rem/16);
    }
}
.product-teaser__title {
    font-weight: var(--font-weight-bold);
    font-size: calc(16rem / 16);
    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}
.product-teaser__content {
    font-size: calc(12rem/16);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.product-teaser__body {
    margin-top: calc(20rem/16);
}
.product-teaser__info-text {
    font-size: calc(12rem/16);
    position: relative;
}
.product-teaser__info-img {
    min-width: calc(30rem/16);
    width: calc(30rem/16);
    filter: grayscale(1);
    @media screen and (min-width: 768px) {
        min-width: calc(56rem/16);
        width: calc(56rem/16);
    }
}
.product-teaser__product {
    max-width: calc(110rem/16);
    margin-left: auto;
    @media screen and (min-width: 768px) {
        max-width: initial;
    }
}