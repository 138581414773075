.nav-overlay {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-out, visibility 0.001s 0.2s ease-out;
    position: absolute;
    top: 100%;
    background: #fff;
    width: calc(291rem / 16);
    padding: calc(40rem / 16) 0;

    @media screen and (max-width: 767px) {
        display: none;
    }

    &.nav-overlay--level-2 {
        top: 0;
        left: 100%;
        background-color: rgba(255, 255, 255, 0.9);
    }

    &.nav-overlay--level-1 {
        @media screen and (min-width: 768px) {
            &:before {
                content: var(--icon-bg-arrowtip);
                font-family: 'iconfont';
                color: #fff;
                display: block;
                position: absolute;
                top: 0;
                left: 26%;
                pointer-events: none;
                font-size: calc(80rem / 16);
                transform: translateX(-50%) translateY(-52%);
            }
        }
    }

    &.show-menu {
        visibility: visible;
        opacity: 1;
        display: block;
        transition: opacity 180ms ease-out, visibility 0.001ms;

        @media screen and (max-width: 767px) {
            position: absolute;
            overflow-y: auto;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
            z-index: 99;
            padding-top: calc(10rem / 16);
        }
    }
}

.nav-overlay__link-text {
    display: inline-block;
    transition: transform 0.2s ease;
    margin-left: calc(10rem / 16);

    @media screen and (max-width: 767px) {
        //width: 100%;
        display: flex;

        > sup {
            top: 0;
        }
    }
}

.nav-overlay__link-text--has-children {
    @media screen and (max-width: 767px) {
        justify-content: space-between;
    }
}

.nav-overlay__link {
    font-size: calc(16rem / 16);
    line-height: calc(22 / 16);
    color: var(--color-primary);
    display: flex;
    align-items: center;
    transition: background-color 0.2s ease;
    padding: calc(8rem / 16) calc(40rem / 16);

    &:before {
        content: '';
        display: inline-flex;
        border-radius: calc(5rem / 16);
        width: calc(10rem / 16);
        height: calc(2rem / 16);
        background-color: var(--product-color);
        transition: transform 0.2s ease;
        transform-origin: left;
        align-items: center;
    }
}

.nav-overlay__link--is-new {
    color: var(--product-color);
    display: flex;
    font-size: calc(14rem / 16);
    font-weight: var(--font-weight-bold);
    letter-spacing: calc(1rem / 16);
    margin-left: auto;
}

.nav-overlay__link:hover, .nav-overlay__item.is-open > .nav-overlay__link {
    background-color: var(--color-light-grey);
    color: var(--color-primary);

    &:before {
        transform: scaleX(2);
    }

    .nav-overlay__link-text {
        transform: translateX(10px);
    }
}

.nav-overlay__title {
    text-transform: uppercase;
    display: block;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
    font-size: calc(18rem / 16);
    padding-left: calc(30rem / 16);
    margin-top: calc(60rem / 16);
    margin-bottom: calc(15rem / 16);
    @media screen and (min-width: 1200px) {
        font-size: calc(24rem / 16);
    }
    @media screen and (min-width: 767px) {
        margin-top: calc(20rem / 16);
        font-size: calc(18rem / 16);
    }
}

.nav-overlay__go-back {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: var(--color-primary);
    margin-left: calc(20rem / 16);
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 2;

    @media screen and (min-width: 768px) {
        display: none;
        line-height: 1;
    }
}