.highlight-area__title {
    font-size: clamp(calc(30rem/16), 3vw, calc(56rem/16));
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.5px;
    text-transform: uppercase;
    @media screen and (min-width: 768px) {
        max-width: 50%;
    }
}
.highlight-area__year {
    color: var(--color-primary-lightest);
    font-size: calc(110rem/16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.5px;
    margin-left: auto;
    margin-top: calc(-40rem/16);
    margin-bottom: calc(20rem/16);
    text-transform: uppercase;
    line-height: 0.5;
    z-index: -1;
    text-align: right;
    position: relative;
    @media screen and (min-width: 768px) {
        right:0;
        top:0;
        margin-top: 0;
        position: absolute;
        margin-bottom:0;
        font-size: clamp(calc(300rem/16), 22vw, calc(440rem/16));
    }
}
.highlight-area__img-grid-wrapper {
    position: relative;
    padding-top: clamp(calc(60rem/16), 11vw, calc(210rem/16));

    &:before {
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-primary-lightest);
        clip-path: polygon(0 0, 100% 2%, 100% 91%, 0% 100%);

        @media screen and (min-width: 768px) {
            clip-path: polygon(0 0, 100% 13%, 100% 82%, 0% 100%);
        }
    }
}
.highlight-area__wysiwyg {
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}
.highlight-area__wysiwyg--first {
    margin-bottom: calc(20rem/16);
    grid-area: left-text;

    @media screen and (min-width:  1200px) {
        max-width: 80%;
        margin-bottom: calc(50rem/16);
    }
}
.highlight-area__content {
    text-transform: uppercase;
    font-size: calc(26rem/16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: var(--color-basica-classic);
    line-height: 1.2;

    @media screen and (min-width:  768px) {
        font-size: clamp(calc(28rem/16), 2.2vw, calc(42rem/16));
    }
}
.highlight-area__img-grid--left {
    display: grid;
    grid-template-columns: 65% 1fr;
    grid-template-rows: auto auto auto;
    gap: calc(15rem/16) calc(15rem/16);
    align-items: flex-end;
    grid-template-areas:
            "left-text left-text"
            "left-img-first left-img-second"
            "left-text-second left-text-second";

    @media screen and (min-width:  768px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto;
        gap: calc(40rem/16) calc(40rem/16);
        align-items: flex-start;
        grid-template-areas:
            "left-text left-text"
            "left-img-first left-img-first"
            "left-text-second left-img-second";
    }
}
.highlight-area__wysiwyg--second {
    grid-area: left-text-second;
}
.highlight-area__img-left--first {
    grid-area: left-img-first;
}
.highlight-area__img-left--second {
    grid-area: left-img-second;
}
.highlight-area__img-grid--right {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto auto;
    gap: calc(15rem/16) calc(15rem/16);
    align-items: flex-start;
    grid-template-areas:
            "right-first right-second"
            "right-third right-forth";

    @media screen and (min-width:  768px) {
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: auto auto;
        gap: calc(40rem/16) calc(40rem/16);
        align-items: flex-start;
        grid-template-areas:
            "right-img-first right-img-second"
            "right-img-third right-img-forth"
    }
}
.highlight-area__img-right--first {
    grid-area: right-img-first;
    grid-column: span 6;
    grid-row: 1;
    @media screen and (min-width:  768px) {
        grid-column: span 5;
    }
}
.highlight-area__img-right--second {
    grid-area: right-img-first;
    grid-column: span 6;
    grid-row: 1;
    @media screen and (min-width:  768px) {
        grid-column: span 7;
    }
}
.highlight-area__img-right--third {
    grid-area: right-img-first;
    grid-column: span 7;
    grid-row: 2;
    @media screen and (min-width:  768px) {

    }
}
.highlight-area__img-right--forth {
    grid-area: right-img-first;
    grid-column: span 5;
    grid-row: 2;
}