.recipe-ingredients{
    z-index: 5;
    position: relative;
}

.recipe-ingredients__list  {
    list-style: none;
    padding: 0;
    margin: 0;
}

.recipe-ingredients__list li {
    padding-left: 1.5rem;
    padding-bottom: calc(4rem /16);
    position: relative;
    @media screen and (min-width: 768px){
        padding-left: calc(60rem /16);
    }
}

.recipe-ingredients__list li:before {
    position: absolute;
    left: 0;
    top: calc(12rem/16);
    content: "";
    width: calc(10rem/16);
    height: calc(1rem/16);
    background-color: var(--color-primary);
    @media screen and (min-width: 768px){
        top: calc(15rem/16);
        width: calc(20rem/16);
    }
}