:root {
    --nav-height: calc(100rem/16);
    --nav-height--xs: calc(50rem/16);
}
.main-navbar-wrapper {
    height: var(--nav-height--xs);
    @media screen and (min-width: 768px) {
        height: var(--nav-height);
    }
}
.main-navbar {
    height: var(--nav-height--xs);
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 90;
    @media screen and (min-width: 768px) {
        height: var(--nav-height);
    }
}
.main-navbar--product-color {
    @media screen and (max-width: 767px) {
        background-color: var(--product-color);
        color:#fff;

        & .main-navbar__search-button {
            color:#fff;
        }

        & .main-navbar__toggle__line {
            background-color:#fff;
        }

        & .main-navbar__brand {
            filter: brightness(0) invert(1);
        }
    }
}
.main-navbar__bottom {
    @media screen and (max-width: 767px) {
        padding-left: calc(30rem/16);
        padding-right: calc(20rem/16);
        background-color: var(--color-light-grey);
        clip-path: polygon(0 0, 100% 33%, 100% 100%, 0% 100%);
        padding-top: calc(40rem/16);
        margin-top: calc(-60rem/16);
    }
}
.main-navbar__container {
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-template-rows: 1fr;
    grid-template-areas:
    "brand additional main";

    @media screen and (min-width: 768px) {
        grid-template-columns: auto 1fr auto;
        grid-template-areas: "brand main additional";
    }
}
.main-navbar__main {
    display: flex;
    grid-area: main;
    height: var(--nav-height--xs);
    @media screen and (min-width: 768px) {
        height: var(--nav-height);
    }
}
.main-navbar__brand-wrapper {
    grid-area: brand;
    z-index: 99;
    padding-left: clamp(calc(10rem/16), 1.9vw, calc(40rem/16));
    padding-right: clamp(calc(10rem/16), 1.9vw, calc(40rem/16));
    height: var(--nav-height--xs);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media screen and (min-width: 768px) {
        height: var(--nav-height);
    }
}
.main-navbar__brand {
    max-width: clamp(calc(70rem/16), 7.3vw, calc(140rem/16));
}
.main-navbar__scroll-container {
    @media screen and (max-width: 767px) {
        overflow-y: scroll;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        -webkit-overflow-scrolling: touch;
        padding-left: calc(30rem/16);
        padding-right: calc(20rem/16);
    }
}
.main-navbar__list {
    display: flex;
    @media screen and (max-width: 767px) {
        flex-direction: column;
        padding-bottom: calc(60rem/16);
    }
}
.main-navbar__list-wrapper {

    @media scren and (min-width: 768px) {
        display: flex;
    }

    @media screen and (max-width: 767px) {
        opacity: 0;
        display: none;
        visibility:  hidden;
        pointer-events: none;
        transition: opacity .1s .2s,visibility .6s .1ms,transform .3s ease-in-out;
        flex-direction: column;
        padding-top: calc(20rem/16);
        box-shadow: 0 0 20px 0 rgba(29,29,27,0.1);

        &.show-menu {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            transform: translateX(0);
            transition: opacity .1s, visibility .1ms, transform .3s ease-in-out;
            position: fixed;
            top: var(--nav-height--xs);
            left: 0;
            width: 100%;
            bottom: 0;
            background-color: #fff;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr calc(126rem/16);
        }
    }
}
.main-navbar__list-item {
    display: inline-flex;
}
.main-navbar__link {
    line-height: 1;
    display: grid;
    width: 100%;
    gap: 0 calc(10rem/16);
    grid-template-columns: 1fr auto;
    grid-template-areas: ". .";
    padding-top: calc(15rem/16);
    padding-bottom: calc(15rem/16);

    @media screen and (min-width: 768px) {
        justify-content: center;
        flex-direction: column;
        display: inline-flex;
        padding-top: 0;
        padding-bottom:  0;
        padding-left: clamp(calc(5rem/16), 1.2vw, calc(35rem/16));
        padding-right: clamp(calc(5rem/16), 1.2vw, calc(35rem/16));
        height: var(--nav-height);
        font-size: clamp(calc(10rem/16), 0.9vw, calc(16rem/16));
    }
    @media screen and (min-width: 1200px) {
        padding-left: clamp(calc(5rem/16), 1.7vw, calc(35rem/16));
        padding-right: clamp(calc(5rem/16), 1.7vw, calc(35rem/16));
    }
}
.main-navbar__additional-list .main-navbar__link-text {
    margin-bottom: 0;
}
.main-navbar__language-link {
    font-size: 1rem;
    color: var(--color-mid-grey);
    text-transform: uppercase;
    border: none;
    box-shadow: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    padding-left:0;
    margin-top: calc(5rem/16);

    @media screen and (min-width: 768px) {
        margin-left: calc(35rem/16);
        margin-top: 0;
    }
}
.main-navbar__language-icon {
    font-size: calc(24rem/16);
}
.main-navbar__link:hover, .main-navbar__list-item.is-open .main-navbar__link {
    .main-navbar__link-text {
        color: var(--color-primary);
        transform: translateY(50%);
    }

    .main-navbar__link-subtext {
        opacity: 0;
    }
}
.main-navbar__link-text {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    line-height: calc(20/16);
    color: var(--color-dark);
    letter-spacing: 0.2px;
    margin-bottom: calc(8rem/16);
    transition: transform 0.2s ease;

    @media screen and (max-width: 767px) {
        font-size: calc(24rem/16);
        color: var(--color-primary);
        margin-bottom: calc(5rem/16);
    }
}
.main-navbar__link-text--small {
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    color: var(--color-primary);
}
.main-navbar__link-subtext {
    color: var(--color-dark);
    opacity:1;
    transition: opacity 0.2s ease;

    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}
.main-navbar__additional {
    grid-area: additional;

    @media screen and (min-width: 768px) {
        background-color: rgba(29,29,27,0.1);
        height: calc(150rem/16);
        padding-left: calc(20rem/16);
        padding-right: calc(30rem/16);
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%);

        &.main-navbar__additional--hide-newsletter {
            height: calc(100rem/16);
            clip-path: polygon(0 0, 100% 0%, 100% 100%, 7% 100%);
        }
    }
}
.main-navbar__additional-list {
    display: flex;
    align-items:center;
    justify-content: center;
    height: var(--nav-height--xs);

    @media screen and (min-width: 768px) {
        height: var(--nav-height);
    }
}
.main-navbar__search-button {
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-size: calc(22rem/16);
    padding: calc(10rem/16);
    display: flex;

    @media screen and (min-width: 768px) {
        font-size: calc(26rem/16);
        padding:calc(10rem/16) calc(10rem/16) calc(10rem/16) calc(20rem/16);
    }
}
.main-navbar__newsletter-btn {
    position: absolute;
    top: var(--nav-height);
    width: 100%;
    height: calc(50rem/16);
    color:#fff;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: calc(-10rem/16);
    font-size: calc(16rem/16);
    background-color: var(--color-primary);
    transition: background-color 0.2s ease;

    &:hover {
        color:#fff;
        background-color: var(--color-primary-dark);
    }
}
.main-navbar__newsletter-btn-icon {
    margin-left: calc(10rem/16);
    font-size: calc(24rem/16);
}
.main-navbar__link-arrow {
    color: var(--color-primary);
    font-size: calc(24rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
}
.main-navbar__search-overlay {
    position: absolute;
    left:0;
    z-index: 999;
    width: 100%;
    height: calc(47rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    visibility: hidden;
    padding: calc(10rem/16);
    opacity: 0;
    transition: opacity 0.2s ease-out, visibility 0.001s 0.2s ease-out;

    @media screen and (min-width: 768px) {
        height: calc(100rem/16);
    }

    &.is-open {
        visibility: visible;
        opacity: 1;
        display: flex;
        transition: opacity 180ms ease-out, visibility 0.001ms;
    }
}
.main-navbar__search-overlay-inner {
    width: 100%;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
        width: calc(520rem/16);
    }
}
.main-navbar__frequently-searched {
    width: 100%;
    margin: 0 auto;
    top: calc(47rem/16);
    position: absolute;
    background-color:#fff;
    color: var(--color-light-grey--dark);
    padding: calc(30rem/16) 0;

    @media screen and (min-width: 768px) {
        padding: calc(45rem/16) 0;
        top: calc(100rem/16);
        width: calc(520rem/16);
        box-shadow: 0 0 20px 0 rgba(29,29,27,0.1);

        &:before {
            content: var(--icon-bg-arrowtip);
            font-family: 'iconfont';
            color:#fff;
            display: block;
            position: absolute;
            top: 0;
            left: 26%;
            pointer-events: none;
            font-size: calc(80rem/16);
            transform: translateX(-50%) translateY(-52%);
        }
    }
}
.main-navbar__frequently-searched-title {
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    font-size: calc(16rem/16);
    padding-left: calc(30rem/16);
    padding-right: calc(30rem/16);
    margin-bottom: calc(12rem/16);

    @media screen and (min-width: 768px) {
        padding-left: calc(40rem/16);
        padding-right: calc(40rem/16);
    }
}
.main-navbar__frequently-searched-link {
    width: 100%;
    display: block;
    padding: calc(8rem/16) calc(40rem/16);
    font-size: calc(16rem/16);

    &:hover {
        background-color: var(--color-light-grey);
        color: var(--color-primary);
    }
}
.main-navbar__search-overlay-icon {
    font-size: calc(26rem/16);
    border: none;
    box-shadow: none;
    background-color: transparent;
}
.main-navbar__search-overlay-close {
    background-color: transparent;
    border: none;
    font-size: calc(20rem/16);
}
.main-navbar__search-input {
    padding: calc(10rem/16);
    font-size: calc(16rem/16);
}
.main-navbar__social-list {
    display: flex;
    justify-content: center;
    margin-top: calc(15rem/16);
    margin-bottom: calc(15rem/16);
}
.main-navbar__social-item {
    color: var(--color-text-default);
    padding-left: calc(22rem/16);
    padding-right: calc(22rem/16);
    font-size: calc(24rem/16);
}