.multiple-selects-dropdown__button{
    text-align: left;
    font-family: var(--font-default);
    padding: 1.25rem 1.25rem .5rem;
    box-shadow: 0 .625rem 1.25rem 0 rgba(1,25,38,0.08);
}

.multiple-selects-dropdown__label{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
}

.has-value .multiple-selects-dropdown__label{
    text-transform: uppercase;
    left: 1.25rem;
    top: .875rem;
    font-size: 0.625rem;
}

.multiple-selects-dropdown__values{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px;
    display: block;
}

.multiple-selects-dropdown__icon{
    position: absolute;
    right: calc(1rem / 16);
    top: calc(1rem / 16);
    bottom: calc(1rem / 16);
    background: var(--color-light-grey);
    color: var(--color-primary);
    width: calc(66rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 13% 100%);
    font-size: calc(22rem / 16);
    pointer-events: none;

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
    }
}


.multiple-selects-dropdown__menu{
    width: 100%;
    padding: 0;
    border-radius: 0;
    box-shadow: 0 .625rem 1.25rem 0 rgba(1,25,38,0.08);
    border: 0;
}


.multiple-selects-dropdown__list{
    max-height: calc(215rem/16);
    overflow-y: auto;
}


.multiple-selects-dropdown__submit-btn{
    height: calc(50rem/16);
    width: calc(100% - 0.5rem);

    @media screen and (max-width: 767px){
        font-size: calc(10rem/16);
        letter-spacing: calc(2.5rem/16);
        height: calc(40rem/16);
    }
}