.content-slide-wrapper {
    background-color: var(--color-primary-lightest-30);
    position: relative;
}
.content-slide-wrapper--product {
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: calc(3rem/16);
        background-color: var(--product-color);
        opacity: 1;
        bottom:0;
    }
}
.content-slide {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-template-areas:
    "content-slide__content"
    "content-slide__main";

    @media screen and (min-width: 768px){
        min-height: calc(490rem/16);
        max-width: 100vw;
        grid-template-columns: 51% 49%;
        grid-template-rows: 1fr;
        gap: 0 0;
        grid-template-areas:
        "content-slide__content content-slide__main";
    }
}
.content-slide--mobile-reversed {
    grid-template-areas:
    "content-slide__main"
    "content-slide__content";
}
.content-slide__content {
    grid-area: content-slide__content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: calc(30rem/16);
    padding-right: calc(30rem/16);
    padding-bottom: calc(30rem/16);
    position: relative;

    @media screen and (max-width: 767px) {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--product-color);
            opacity: 0.3;
        }
    }

    @media screen and (min-width: 768px) {
        padding: 0;
        background-color: transparent;
    }
}
.content-slide__main {
    grid-area: content-slide__main;
    position: relative;
    padding-left: calc(30rem/16);
    padding-right: calc(30rem/16);
}
.content-slide__main:before {
    content:'';
    position: absolute;
    left: 0;
    width: 100%;
    height: 40%;
    bottom: 0;
    background-color: var(--product-color);
    opacity: 0.3;
    clip-path: polygon(0 0, 100% 17%, 100% 100%, 0 100%);

    @media screen and (min-width: 768px) {
        width: 80%;
        height: 100%;
        clip-path: polygon(0 0,100% 0,65% 100%,12% 100%);
    }
}
.content-slide__product-wrapper {
    height: 100%;
    margin-left: auto;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    flex-direction: row-reverse;
    padding-top: calc(10rem/16);
    padding-bottom: calc(10rem/16);

    @media screen and (min-width: 768px) {
        align-items: center;
        padding: 0;
    }

    @media screen and (min-width: 1200px) {
        max-width: 65%;
    }
}
.content-slide__product {
    max-width: calc(260rem/16);

    @media screen and (min-width: 768px) {
        max-width: calc(380rem/16);
    }
}
.content-slide__test-icon {
    font-size: calc(120rem/16);
    margin-bottom: calc(40rem/16);
    color: #000;

    @media screen and (max-width: 767px) {
        display: none;
    }
}
.content-slide__text-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.content-slide__quotation{
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    font-size: calc(26rem/16);
    color: var(--color-primary);
    line-height: calc(52/42);
    position: relative;
    margin-left: calc(14rem /16);

    @media screen and (min-width: 768px) {
        font-size: calc(42rem/16);
        max-width: calc(720rem /16);
    }

    &::before {
        content: "“";
        font-family: var(--font-default-bold);
        font-weight: var(--font-weight-bold);
        font-size: calc(26rem/16);
        color: var(--color-primary);
        line-height: calc(52/42);
        position: absolute;
        top: 0;
        left: calc(-14rem /16);
        @media screen and (min-width: 768px) {
            font-size: calc(42rem/16);
            left: calc(-22rem /16);
        }
    }
}
.content-slide__author-image__wrapper{
    max-width: calc(140rem /16);
    max-height: calc(140rem /16);
    border-radius: 50%;
    border: calc(10rem / 16) solid #fff;
}
.content-slide__author-image{
    border-radius: 50%;
}
.content-slide__author{
    color: var(--color-mid-grey);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    margin-top: calc(20rem/16);
}
.content-slide__text-wrapper--quote{
    padding: calc(20rem /16) 0;
    @media screen and (min-width: 768px) {
        width: clamp(calc(300rem /16), 27.5vw, calc(490rem /16));
        max-width: calc(490rem /16);
        padding: 0;
    }
}
.content-slide__test-btn-wrapper {
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}
.content-slide__test-btn {
    width: 100%;
    @media screen and (min-width: 768px) {
        width: calc(380rem/16);
    }
}
.content-slide--basica-test .content-slide__content {
    @media screen and (max-width: 767px) {
        padding-top: calc(48rem/16);
    }
}
.content-slide--basica-test .content-slide__main {
    @media screen and (max-width: 767px) {
        padding-bottom: calc(48rem/16);

        &:before {
            height: 100%;
            clip-path: none;
        }
    }
}