.image-slide {
    background-color: var(--color-primary-lightest-30);
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    gap: calc(15rem/16) 0;
    grid-template-areas:
    "image-slide__image"
    "image-slide__content";

    @media screen and (min-width: 768px){
        max-width: 100vw;
        grid-template-columns: 51% 49%;
        grid-template-rows: 1fr;
        gap: 0 0;
        grid-template-areas:
        "image-slide__content image-slide__image";
    }
}

.image-slide__content {
    grid-area: image-slide__content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: calc(15rem / 16) calc(30rem /16) calc(45rem /16);
    @media screen and (min-width: 768px){
        padding: calc(40rem/16);
    }
    @media screen and (min-width: 1640px){
        padding-left: calc((100vw - calc(1640rem/16)) / 2);
    }
}

.image-slide__image {
    position: relative;
    grid-area: image-slide__image;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 88%);
    @media screen and (min-width: 768px){
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%);
    }
}

.image-slide__image__copyright {
    position: absolute;
    right: calc(10rem /16);
    top: calc(10rem /16);
    color: #fff;
    font-size: calc(14rem /16);
    text-shadow: 0 0 10px 0 rgba(29,29,27,0.7);
    @media screen and (min-width: 768px){
        bottom: calc(20rem /16);
        right: calc(30rem /16);
        top: unset;
    }
}

.image-slide__subtitle {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    font-size: calc(16rem/16);
    color: var(--color-mid-grey);
    line-height: calc(28/20);
    margin-bottom: calc(10rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}

.image-slide__title {
    color: var(--color-primary);
    margin-bottom: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(20rem/16);
    }
}