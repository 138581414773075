.modal {
    padding-left: 0 !important;
}

.modal__close {
    width: calc(50rem / 16);
    height: calc(50rem / 16);
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff;
    color: var(--color-mid-grey);
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: calc(12rem / 16);
    z-index: 31;
    transition: color 0.2s ease-in, background-color 0.2s ease-in;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);

    @media screen and (min-width: 768px) {
        width: calc(60rem / 16);
        height: calc(60rem / 16);
        padding: calc(20rem / 16);
    }
}

.modal__close--grey {
    color: var(--color-text-default);
    background-color: var(--color-light-grey);
}
.modal__title,
.modal__subtitle {
    text-align: center;
    margin-bottom: 1rem;

    @media screen and (min-width: 768px) {
        margin-bottom: calc(24rem / 16);
    }
}

.modal__close-icon {
    font-size: calc(20rem / 16);
}

.modal-dialog:not(.modal-fullscreen) .modal-content {
    border: none;
    outline: none;
    border-radius: 0;
}

.modal__img {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 75%);
}

.modal-body {
    @media screen and (min-width: 768px) {
        padding: calc(40rem / 16) clamp(calc(40rem / 16), 8vw, calc(140rem / 16)) calc(80rem / 16);
    }
}

.modal__title {
    color: var(--color-primary);
    font-size: calc(28rem / 16);
    font-weight: var(--font-weight-bold);
    font-family: var(--font-default-bold);
    text-align: left;

    @media screen and (min-width: 768px) {
        font-size: calc(42rem / 16);
    }
}

.modal-sharing .modal-body {
    @media screen and (min-width: 768px) {
        padding: calc(80rem / 16) calc(100rem / 16);
    }
}

.modal-product__header {
    background-color: var(--product-color);
    color: #fff;
    font-size: calc(20rem/16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    height: calc(50rem/16);
    padding: 0 calc(10rem/16);
    display: flex;
    align-items: center;
    @media screen and (min-width: 768px) {
        height: calc(60rem/16);
        padding: calc(16rem / 16) clamp(calc(40rem / 16), 8vw, calc(140rem / 16));
    }
}

.modal-fullscreen {
    width: 100%;
    max-width: 100%;
    margin: 0;
}