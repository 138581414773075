@font-face {
    font-family: "iconfont";
    src: url('/static/icons/font/iconfont.woff2') format('woff2'),
    url('/static/icons/font/iconfont.woff') format('woff'),
    url('/static/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-addthis:before { content: "\EA01" }
.icon-arrow-down:before { content: "\EA02" }
.icon-arrow-left:before { content: "\EA03" }
.icon-arrow-right:before { content: "\EA04" }
.icon-arrow-up:before { content: "\EA05" }
.icon-bg-arrowtip:before { content: "\EA06" }
.icon-camera:before { content: "\EA07" }
.icon-check:before { content: "\EA08" }
.icon-checkbox-empty:before { content: "\EA09" }
.icon-checkbox-fill:before { content: "\EA0A" }
.icon-checkmark-circle:before { content: "\EA0B" }
.icon-checkmark:before { content: "\EA0C" }
.icon-chevron-down:before { content: "\EA0D" }
.icon-chevron-left:before { content: "\EA0E" }
.icon-chevron-right:before { content: "\EA0F" }
.icon-chevron-up:before { content: "\EA10" }
.icon-clock:before { content: "\EA11" }
.icon-close:before { content: "\EA12" }
.icon-copy:before { content: "\EA13" }
.icon-dashboard:before { content: "\EA14" }
.icon-delete-keyboard:before { content: "\EA15" }
.icon-difficulty-easy:before { content: "\EA16" }
.icon-difficulty-hard:before { content: "\EA17" }
.icon-difficulty-medium:before { content: "\EA18" }
.icon-download:before { content: "\EA19" }
.icon-eating-set:before { content: "\EA1A" }
.icon-effect:before { content: "\EA1B" }
.icon-elements:before { content: "\EA1C" }
.icon-energy:before { content: "\EA1D" }
.icon-facebook:before { content: "\EA1E" }
.icon-fax:before { content: "\EA1F" }
.icon-file:before { content: "\EA20" }
.icon-filter:before { content: "\EA21" }
.icon-flask:before { content: "\EA22" }
.icon-hover:before { content: "\EA23" }
.icon-info:before { content: "\EA24" }
.icon-instagram:before { content: "\EA25" }
.icon-language:before { content: "\EA26" }
.icon-lightbulb:before { content: "\EA27" }
.icon-linkedin:before { content: "\EA28" }
.icon-lips:before { content: "\EA29" }
.icon-mail:before { content: "\EA2A" }
.icon-menu:before { content: "\EA2B" }
.icon-microphone:before { content: "\EA2C" }
.icon-minus:before { content: "\EA2D" }
.icon-phone:before { content: "\EA2E" }
.icon-pinterest:before { content: "\EA2F" }
.icon-play-circle:before { content: "\EA30" }
.icon-play:before { content: "\EA31" }
.icon-plus:before { content: "\EA32" }
.icon-print:before { content: "\EA33" }
.icon-radiobutton-empty:before { content: "\EA34" }
.icon-radiobutton-fill:before { content: "\EA35" }
.icon-search:before { content: "\EA36" }
.icon-send:before { content: "\EA37" }
.icon-share:before { content: "\EA38" }
.icon-shipment-box:before { content: "\EA39" }
.icon-sound-wave:before { content: "\EA3A" }
.icon-stopwatch:before { content: "\EA3B" }
.icon-task-list-question:before { content: "\EA3C" }
.icon-timer:before { content: "\EA3D" }
.icon-twitter:before { content: "\EA3E" }
.icon-whatsapp:before { content: "\EA3F" }
.icon-youtube:before { content: "\EA40" }


:root {
--icon-addthis: "\EA01";
    --icon-arrow-down: "\EA02";
    --icon-arrow-left: "\EA03";
    --icon-arrow-right: "\EA04";
    --icon-arrow-up: "\EA05";
    --icon-bg-arrowtip: "\EA06";
    --icon-camera: "\EA07";
    --icon-check: "\EA08";
    --icon-checkbox-empty: "\EA09";
    --icon-checkbox-fill: "\EA0A";
    --icon-checkmark-circle: "\EA0B";
    --icon-checkmark: "\EA0C";
    --icon-chevron-down: "\EA0D";
    --icon-chevron-left: "\EA0E";
    --icon-chevron-right: "\EA0F";
    --icon-chevron-up: "\EA10";
    --icon-clock: "\EA11";
    --icon-close: "\EA12";
    --icon-copy: "\EA13";
    --icon-dashboard: "\EA14";
    --icon-delete-keyboard: "\EA15";
    --icon-difficulty-easy: "\EA16";
    --icon-difficulty-hard: "\EA17";
    --icon-difficulty-medium: "\EA18";
    --icon-download: "\EA19";
    --icon-eating-set: "\EA1A";
    --icon-effect: "\EA1B";
    --icon-elements: "\EA1C";
    --icon-energy: "\EA1D";
    --icon-facebook: "\EA1E";
    --icon-fax: "\EA1F";
    --icon-file: "\EA20";
    --icon-filter: "\EA21";
    --icon-flask: "\EA22";
    --icon-hover: "\EA23";
    --icon-info: "\EA24";
    --icon-instagram: "\EA25";
    --icon-language: "\EA26";
    --icon-lightbulb: "\EA27";
    --icon-linkedin: "\EA28";
    --icon-lips: "\EA29";
    --icon-mail: "\EA2A";
    --icon-menu: "\EA2B";
    --icon-microphone: "\EA2C";
    --icon-minus: "\EA2D";
    --icon-phone: "\EA2E";
    --icon-pinterest: "\EA2F";
    --icon-play-circle: "\EA30";
    --icon-play: "\EA31";
    --icon-plus: "\EA32";
    --icon-print: "\EA33";
    --icon-radiobutton-empty: "\EA34";
    --icon-radiobutton-fill: "\EA35";
    --icon-search: "\EA36";
    --icon-send: "\EA37";
    --icon-share: "\EA38";
    --icon-shipment-box: "\EA39";
    --icon-sound-wave: "\EA3A";
    --icon-stopwatch: "\EA3B";
    --icon-task-list-question: "\EA3C";
    --icon-timer: "\EA3D";
    --icon-twitter: "\EA3E";
    --icon-whatsapp: "\EA3F";
    --icon-youtube: "\EA40";
    
}