.breadcrumb {
    padding-top: calc(9rem/16);
    padding-bottom: calc(9rem/16);
}
.breadcrumb-item {
    font-size: calc(16rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}
.breadcrumb-item:not(:first-of-type) {
    &:before {
        content: "/";
        margin-right: calc(10rem/16);
        @media screen and (min-width: 768px) {
            margin-right: calc(16rem/16);
        }
    }
}
.breadcrumb-item.active {
    color: var(--color-primary);
}