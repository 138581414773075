.product-presentation-area {
    position: relative;
    padding-bottom: calc(80rem/16);
    overflow-x: hidden;

    &:before {
        content:'';
        bottom:0;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 90%;
        clip-path: polygon(0 0, 100% 8%, 100% 100%, 0% 100%);
        background-color: var(--color-primary-lightest);

        @media screen and (min-width: 768px) {
            clip-path: polygon(0 0, 100% 12%, 100% 100%, 0% 100%);
        }
    }
}