.small-media-wrapper {
    overflow: hidden;
}
.small-media__media {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        background: var(--color-primary-lightest);
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 100%;
        left: -100%;
        @media screen and (min-width: 1920px){
            width: calc(((100vw - 1920px) / 2) + 100%);
            left: unset;
        }
    }
}
.small-media__media--right {
    &:before {
        left: 100%;

        @media screen and (min-width: 1920px){
            left: 100%;
        }
    }
}

.small-media__copyright {
    color: var(--color-light-grey--dark);
    font-size: clamp(calc(14rem / 16), 1.5vw, calc(20rem / 16));
    margin-top: calc(4rem / 16);
}