.product-info{
    @media screen and (max-width: 767px){
        display: flex;
        justify-content: center;
        align-items: center;
        padding: calc(5rem/16);
        min-width: calc(85rem/16);
        flex-direction: inherit;
        text-align: center;
        gap: calc(4rem/16);
    }
}

.product-info.product-info__mb{
    @media screen and (min-width: 768px){
        margin-bottom: calc(24rem/16);
    }
}

.product-info__img {
    height: calc(30rem/16);
    width: calc(30rem/16);
    filter: grayscale(1);

    @media screen and (min-width: 768px) {
        height: calc(50rem/16);
        width: calc(50rem/16);
    }

    @media screen and (max-width: 767px){

    }
}
.product-info__text {
    color: var(--color-mid-grey);
    @media screen and (min-width: 768px){
      font-size: calc(16rem/16);
        line-height: calc(16/12);
        display: block;
        margin-top: calc(10rem/16);
    }

}