.downloads-title{
    font-size: calc(26rem / 16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    @media screen and (min-width: 768px){
        font-size: calc(42rem / 16);
        color: var(--color-primary);
    }
}
.download-teaser__link {
    display: flex;
    align-items: center;
    color: var(--color-primary);
    margin-top: calc(10rem /16);
}

.download-teaser__image {
    position: relative;
}

.download-teaser__badge {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background-color: #00000050;
    height: calc(30rem /16);
    width: calc(30rem /16);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(12rem /16);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%);
    @media screen and (min-width: 768px){
        height: calc(60rem /16);
        width: calc(60rem /16);
        font-size: calc(24rem /16);
    }
}

.download-teaser__icon {
    font-size: calc(24rem /16);
    margin-right: calc(10rem /16);
}

.download-teaser__text{
    margin-top: calc(10rem /16);
}