.img-teaser {
    transition: box-shadow 0.25s ease-in-out;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    &:hover {
        box-shadow: 0 calc(20rem / 16) calc(40rem / 16) 0 rgba(29, 29, 27, 0.1);
    }

    &:hover .img-teaser__link__text::before {
        transform: scaleX(1);
    }

    &:hover .img-teaser__image {
        clip-path: polygon(0 0, 100% 0%, 100% 95%, 0 83%);
    }
}

.img-teaser__image {
    position: relative;
    transition: clip-path 0.2s ease-in;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 88%);
}

.img-teaser__content {
    padding: calc(10rem/16) calc(30rem / 16) calc(30rem/16);
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    height: auto;

    @media screen and (min-width: 768px) {
        padding: calc(40rem / 16);
    }
}

.img-teaser__link {
    color: var(--color-primary);
    font-size: calc(16rem / 16);
    display: flex;
    align-items: center;
    margin-top: auto;

    &:hover {
        color: var(--color-primary);
    }
}

.img-teaser__link__text {
    position: relative;
    display: block;
    line-height: 1.2;

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: calc(1rem / 16);
        transform: scaleX(0);
        background: var(--color-primary);
        transition: transform 0.25s ease-in;
    }
}

.img-teaser__title {
    color: var(--color-primary);
    text-transform: uppercase;
    font-size: calc(20rem / 16);
    font-weight: var(--font-weight-bold);
    font-family: var(--font-bold);
    margin-bottom: calc(20rem / 16);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.img-teaser__text {
    margin-bottom: calc(20rem / 16);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.img-teaser__image {
    position: relative;
}

.img-teaser__badge {
    position: absolute;
    z-index: 1;
    top: calc(20rem / 16);
    font-size: calc(14rem / 16);
    padding: calc(5rem / 16) calc(20rem / 16);
}

.img-teaser__badge--left {
    left: 0;
    background-color: white;
    color: black;
    mix-blend-mode: screen;
    opacity: .7;
    clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
}

.img-teaser__badge--right {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
    right: 0;
    background-color: var(--color-primary);
    color: #fff;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
}