.content-block + .content-block,
.content-block + iframe + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + iframe + .content-block,
.pimcore_area_content + iframe + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(60rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(120rem/16);
    }
}

.content-block__bg-wrapper {
    margin-top: 0 !important;
    padding: calc(60rem / 16) 0;
    @media screen and (min-width: 768px) {
        padding: calc(120rem / 16) 0;
    }
}

/* this should remove the space between this (last) element and the page-footer */
.main-content > .content-block:last-child.content-block--no-footer-margin,
.pimcore_area_content:last-child .content-block.content-block--no-footer-margin {
    margin-bottom: calc(-60rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-120rem/16);
    }
}

.content-visibility--auto .content-block {
    contain-intrinsic-size: 1px;
    content-visibility: auto;
}
