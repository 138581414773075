.nav-tabs {
    border-bottom: calc(1rem / 16) solid var(--color-primary-lightest);
    justify-content: space-between;
    margin-bottom: calc(30rem / 16);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(40rem / 16);

        &.nav-tabs--vertical {
            display: block;
            max-width: calc(240rem/16);
        }
    }
}
.nav-tabs--vertical .nav-item + .nav-item {
    @media screen and (min-width: 768px) {
        margin-top: calc(40rem/16);
    }
}
.nav-link {
    display: flex;
    align-items: center;
    color: var(--color-mid-grey);

    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    background: transparent !important;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    padding: .75rem !important;
    @media screen and (min-width: 768px) {
        font-size: clamp(calc(14rem / 16), 1vw, calc(18rem / 16));
        padding: 0 calc(4rem / 16) calc(12rem / 16) calc(4rem / 16) !important;
        border-bottom: calc(2rem / 16) solid var(--color-light-grey--dark) !important;
    }
}

.nav-link:hover,
.nav-link.active {
    color: var(--color-primary) !important;
    @media screen and (min-width: 768px) {
        border-bottom: calc(2rem / 16) solid var(--color-primary) !important;
    }
}

.nav-icon {
    margin-right: calc(10rem / 16);
    @media screen and (min-width: 768px) {
        font-size: clamp(calc(18rem / 16), 1.5vw, calc(24rem / 16));
        margin-right: clamp(calc(8rem / 16), 1.25vw, calc(20rem / 16));
    }
}