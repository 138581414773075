.product-hero__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
    "wrapper"
    "media";

    @media screen and (min-width: 768px) {
        min-height: calc(100vh - var(--nav-height));
        grid-template-columns: 1fr minmax(calc(450rem/16), 35%);
        gap: calc(40rem/16) calc(40rem/16);
        grid-template-areas: "media wrapper";
    }
}
.product-hero__media {
    grid-area: media;
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);

    @media screen and (min-width: 768px) {
        padding-left: calc((100vw - var(--container-max-width)) /2);
    }
}
.product-hero__media-grid {
    display: grid;
    grid-template-columns: calc(10rem/16) 1fr;
    grid-template-rows: auto 1fr;
    height: 100%;
    gap: calc(30rem/16) calc(40rem/16);
    margin-top: calc(-130rem/16);
    z-index: 2;
    grid-template-areas:
    "product product"
    "facts facts";
    position: relative;
    align-items: flex-end;

    @media screen and (min-width: 768px) {
        gap: calc(0rem/16) calc(20rem/16);
        grid-template-columns: calc(100rem/16) 1fr;
        padding-top: calc(15rem/16);
        margin-top: 0;
        align-items: flex-start;
        grid-template-areas:
        "badges product"
        "facts product";
    }
    @media screen and (min-width: 1400px) {
        gap: 0 calc(20rem/16);
        padding-top: clamp(calc(10rem/16), 3.5vw, calc(80rem/16));
    }
}

.product-hero__wrapper-badge{
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px){
        margin-top: calc(24rem/16);
    }
}
.product-hero__badge-wrapper {
    grid-area:badges;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767px) {
        width: 500%;
        margin-left: calc(-11rem/16);
        flex-direction: column-reverse;
        align-self: center;
    }
}
.product-hero__content-wrapper {
    grid-area: wrapper;
}
.product-hero__content {
    background-color: var(--product-color);
    display: flex;
    flex-direction: column;
    z-index: 9;
    justify-content: space-between;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);
    padding-bottom: calc(140rem/16);


    @media screen and (min-width: 768px) {
        height: calc(100vh - var(--nav-height));
        padding-bottom: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
    }
}
.product-hero__content-inner {
    color: #fff;
    padding: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) clamp(calc(20rem / 16), 6vw, calc(140rem / 16));
        display: flex;
        flex-direction: column;
        flex: 1 auto;
        height: auto;
        justify-content: space-between;
    }
    @media screen and (min-width: 1600px) {
        padding: calc(80rem / 16) clamp(calc(20rem / 16), 8vw, calc(140rem / 16));
    }
}
.product-hero__title {
    margin-bottom: calc(10rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(20rem/16);
        font-size: clamp(calc(30rem/16), 2.9vw, calc(56rem/16));
    }
}
.product-hero__subtitle {
    font-size: calc(22rem/16);
    line-height: calc(38/30);

    @media screen and (min-width: 768px) {
        font-size: clamp(calc(16rem/16), 1.3vw, calc(30rem/16));
    }
}
.product-hero__image {
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 100%;
    grid-area: product;
    align-self: center;

    @media screen and (min-width: 768px) {
        max-width: 36vw;
    }
    @media screen and (min-width: 1600px) {
        width: calc(600rem/16);
    }
}
.product-hero__product-facts {
    color: var(--product-color);
    font-size: calc(12rem/16);
    grid-area:facts;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (min-width: 768px) {
        display: block;
        flex-wrap: initial;
    }
}
.product-hero__product-fact-icon {
    font-size: calc(24rem/16);
    @media (min-width: 768px){
        font-size: calc(40rem/16);
    }
}
.product-hero__product-fact {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc(5rem/16);
    min-width: calc(85rem/16);
    flex-direction: inherit;
    text-align: center;
    @media screen and (max-width: 767px){
        gap: calc(4rem/16);
    }

    @media screen and (min-width: 768px) {
        padding: calc(15rem/16) 0;
        flex-direction: column;
        font-size: calc(16rem/16);
    }
}
.product-hero__badge {
    width: calc(120rem / 16);

    @media screen and (min-width: 768px) {
        width: calc(120rem / 16);
        margin: 0 auto calc(20rem/16);
    }

    @media screen and (min-width: 1400px) {
        margin-bottom: calc(30rem / 16);
        width: calc(180rem/16);
    }
}
.product-hero__buttons {
    background-color: var(--color-light-grey);
    padding: calc(20rem/16) calc(20rem/16) calc(20rem/16) calc(60rem/16);

    @media screen and (min-width: 1400px) {
        padding: calc(40rem/16) calc(80rem/16) calc(40rem/16) calc(140rem/16);
    }

    @media screen and (max-width: 767px) {
        position: fixed;
        bottom:0;
        width: 100%;
        left:0;
        padding: calc(15rem/16);
        z-index: 9;
    }
}