.product-experience-wrapper {
    padding-top: calc(130rem/16);
    padding-bottom: calc(60rem/16);
    transition: background-color 0.2s linear;
    will-change: background-color;
    overflow-x: hidden;

    @media screen and (min-width: 768px) {
        padding-top: calc(200rem/16);
        padding-bottom: calc(200rem/16);
    }
}
.product-experience--is-active {
    background-color:var(--product-color);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.product-experience__product {
    max-width: 70%;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
        max-width: calc(350rem/16);
    }

    @media screen and (min-width: 1400px) {
        max-width: calc(500rem/16);
    }
}
.product-experience {
    min-height: 80vh;

    @media screen and (min-width: 768px) {
        min-height: 200vh;
    }
}
.product-experience__claim {
    text-transform: uppercase;
    color: #fff;
    font-size: calc(32rem/16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    text-align:center;
    line-height: calc(64/56);
    margin: 0 auto;
    margin-top: 15vh;

    @media screen and (min-width: 768px) {
        font-size: clamp(calc(30rem/16), 2.9vw, calc(56rem/16));
        max-width: calc(660rem/16);
        margin-top: 18vh;
    }
}
.product-experience__claim-wrapper {
    padding-bottom: 50vh;
}
.product-experience__product {
    transition: transform 0.5s ease-in-out;
}
.product-experience__product-wrapper.is-affix {
    z-index: 9;

    .product-experience__product {
        transform: scale(0.8) translateY(100px);
        transform-origin: bottom;
    }
}