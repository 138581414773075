.title-block__title {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    font-size: calc(26rem/16);
    color: var(--color-primary);
    line-height: calc(52/42);

    @media screen and (min-width: 768px) {
        font-size: calc(34rem/16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(42rem/16);
    }
}
.title-block--main .title-block__title {
    font-size: calc(32rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(42rem/16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(56rem/16);
    }
}
.title-block__title--big {
    font-size: calc(36rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(56rem/16);
    }
}
.title-block__top-title {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    font-size: calc(16rem/16);
    color: var(--color-mid-grey);
    line-height: calc(28/20);
    margin-bottom: calc(10rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}
.title-block {
    margin-bottom: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(60rem/16);
    }
}
.title-block__intro {
    font-size: calc(16rem/16);
    color: var(--color-mid-grey);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}
.title-block__link {
    color: var(--color-primary);
    font-size: calc(16rem/16);
    display: flex;
    align-items: center;
    margin-top: calc(10rem/16);
}
.title-block__hint {
    margin-top: calc(30rem/16);
    font-size: calc(16rem/16);
    color: var(--color-mid-grey);
    display: flex;
}
.title-block__hint-icon {
    font-size: calc(24rem/16);
}