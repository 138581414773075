.portal-hero {
    position: relative;
    background-color: var(--color-primary-light);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
    "."
    ".";
    align-items: center;
    @media screen and (min-width: 768px) {
        grid-template-areas:". .";
        grid-template-columns: 58% 1fr;
        height: calc(100vh - 100rem/16);
        max-height: calc(900rem/16);
        overflow: hidden;
    }
}
.portal-hero__image-wrapper {
    height: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 81%);
    overflow: hidden;
    @media screen and (min-width: 768px) {
        clip-path: polygon(0 0, 87% 0, 100% 100%, 0% 100%);
    }
}
.portal-hero__image {
    object-fit: cover;
    transform-origin: top center;
    animation: clippedImageWrapper 4s cubic-bezier(.5,0.1,0,1) forwards;
}
.portal-hero__clipped-image {
    transform-origin: top center;
    object-fit: cover;
    animation: clippedImage 4s cubic-bezier(.5,0.1,0,1) forwards;
}
.portal-hero__clipped-image-wrapper {
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
}
@keyframes clippedImageWrapper {
    0% {
        transform: translateY(-5%) translateX(6%) scale(1.1);
    }
    100% {
        transform: translateY(0) translateX(0) scale(1);
    }
}
@keyframes clippedImage {
    0% {
        transform: translateY(-5%) translateX(-6%) scale(1.2);
    }
    100% {
        transform: translateY(0) translateX(0) scale(1);
    }
}
.portal-hero__content {
    color: #fff;
    padding: calc(0rem/16) calc(30rem/16) calc(60rem/16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem/16) clamp(calc(20rem/16), 6.8vw, calc(140rem/16)) calc(30rem/16) clamp(calc(20rem/16), 6.5vw, calc(125rem/16));
    }
}
.portal-hero__wysiwyg {
    color: #fff;
}
.portal-hero__title {
    font-size: calc(36rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);

    @media screen and (min-width: 768px) {
        font-size: clamp(20px, 2.8vw, 3.5rem);
    }

    &:after {
        content: '';
        height: calc(1rem/16);
        background-color: #fff;
        width: 100%;
        display: block;
        margin-top: calc(14rem/16);
        margin-bottom: calc(25rem/16);
    }
}
.portal-hero__wysiwyg {
    font-size: calc(16rem/16);

    @media screen and (min-width: 768px) {
        font-size: clamp(calc(14rem/16), 1vw ,calc(20rem/16));
    }
}
.portal-hero__link {
    position: absolute;
    bottom: calc(42rem/16);
    left: 64.2%;
    color: #fff;
    font-size: calc(16rem/16);
    font-weight: var(--font-weight-default);

    &:hover {
        color: #fff;
    }

    @media screen and (max-width: 767px) {
        display: none;
    }
}