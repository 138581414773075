.sb-test {
    padding: calc(15rem/16);
    @media screen and (min-width: 768px) {
        padding: 0;
    }
}
.sb-test__question {
    font-size: clamp(calc(20rem/16), 9vw, calc(36rem/16));
    line-height: calc(44/36);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    color: var(--color-primary);
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(52rem/16);
    }
}
.sb-test__questions {
    display: flex;
    flex: 1;
}
.sb-test__question-wrapper.is-active {
    opacity: 1;
    display: flex;
    position: relative;
}
.sb-test__question-wrapper {
    opacity: 0;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    padding: calc(40rem/16) 0;
    width: 100%;
}
.sb-test-inner {
    max-width: calc(520rem/16);
    margin: 0 auto;
    height: 91vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (min-width: 768px) {
        min-height: 80vh;
    }
}
.sb-test__nav {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1fr auto;
    gap: calc(10rem/16) calc(5rem/16);
    grid-template-areas:
    ". . ."
    "progress progress progress";

    @media screen and (min-width: 768px) {
        gap: calc(10rem/16) calc(20rem/16);
    }
}
.sb-test__progress-wrapper {
    grid-area: progress;
}
.sb-test__progress {
    max-width: calc(150rem/16);
    margin: 0 auto;
}
.sb-test__inner-nav {
    text-align: center;
}
.sb-test__arrow {
    border: none;
    box-shadow: none;
    background-color: transparent;

    &:disabled {
        opacity: 0.2;
    }
}
.sb-test__arrow .sb-test__arrow-icon {
    height: calc(56rem / 16);
    width: calc(66rem / 16);
    background-color: var(--color-light-grey);
    color: var(--color-primary);
    font-size: calc(25rem / 16);
    display: inline-flex;
    justify-content: center;
    border: none;
    align-items: center;
    transition: background-color 0.2s ease, color 0.2s ease;
}
.sb-test__arrow--next .sb-test__arrow-icon {
    padding-left: calc(10rem / 16);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 14% 100%);
}
.sb-test__arrow--prev .sb-test__arrow-icon {
    padding-right: calc(10rem / 16);
    clip-path: polygon(0 0, 86% 0, 100% 100%, 0 100%);
}
.sb-test__category {
    font-size: clamp(calc(11rem/16), 4vw, calc(14rem/16));
}
.sb-test__counter {
    font-size: clamp(calc(11rem/16), 4vw, calc(14rem/16));
    color: var(--color-light-grey--dark);
}
.sb-test__progress-item {
    height: calc(6rem/16);
    background-color: var(--color-light-grey);
}